import axios from "axios";
import { subtractBusinessDays } from "../formatDate";
import addHistory from "../historyHelper";
import { appointmentConfirmation } from "../scriptTemplates/emailTemplates";
import { confirmDemo } from "../scriptTemplates/phoneTemplates";
import { formatSpelledDate } from "../formatDate";

export async function reschedule({
  unionName,
  data,
  localNumber,
  startTime,
  endTime,
  notes,
  scheduleDate,
  refreshSuspectList,
}) {
  try {
    // Construct the URL to search for tasks with the same unionName and localNumber
    const taskSearchUrl = `https://apijonluca.netlify.app/.netlify/functions/api/tasks?union=${unionName}&localNumber=${localNumber}`;

    // Make an HTTP GET request to search for tasks
    const taskSearchResponse = await axios.get(taskSearchUrl);

    if (taskSearchResponse.status === 200) {
      // Assuming there are at least two matching tasks
      const taskData = taskSearchResponse.data;

      // Create the updated task objects
      const updatedTasks = [
        {
          startTime,
          endTime,
          notes,
          date: scheduleDate,
          completed: false,
        },
        {
          date: subtractBusinessDays(new Date(scheduleDate), 1),
          completed: false,
          script: confirmDemo({
            unionData: data,
            appointmentDate: scheduleDate,
            startTime: startTime,
            endTime: endTime,
          }),
          description: `Call to confirm they are available on ${formatSpelledDate(scheduleDate)}`,
        },
        {
          date: new Date(),
          completed: false,
          script: appointmentConfirmation({
            unionData: data,
            appointmentDate: scheduleDate,
            startTime: startTime,
            endTime: endTime,
          }),
          description: `Confirm they are available on ${formatSpelledDate(scheduleDate)}`,
        },
        {
          date: scheduleDate,
          completed: false,
        },
      ];

      // Update the first and second tasks
      for (let i = 0; i < 4; i++) {
        const taskUpdateUrl = `https://apijonluca.netlify.app/.netlify/functions/api/tasks/${taskData[i]._id}`;

        // Make an HTTP PUT request to update the task
        const taskPutResponse = await axios.put(taskUpdateUrl, updatedTasks[i]);

        if (taskPutResponse.status === 200) {
          console.log(
            `Task ${i + 1} PUT request successful:`,
            taskPutResponse.data,
          );
          // Handle the response data if needed
        } else {
          console.error(
            `Task ${i + 1} PUT request failed:`,
            taskPutResponse.status,
            taskPutResponse.data,
          );
          // Handle error cases for the task PUT request
        }
      }

      // Call the refreshSuspectList function here
      refreshSuspectList();
    } else {
      console.error(
        "Task search GET request failed:",
        taskSearchResponse.status,
        taskSearchResponse.data,
      );
      // Handle error cases for the task search GET request
    }
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle any exceptions here
  }
}

async function deleteTaskById(taskId) {
  try {
    const taskDeleteUrl = `https://apijonluca.netlify.app/.netlify/functions/api/tasks/${taskId}`;

    // Make an HTTP DELETE request to delete the task
    const taskDeleteResponse = await axios.delete(taskDeleteUrl);

    if (taskDeleteResponse.status === 200) {
      console.log(`Task DELETE request successful for task ID: ${taskId}`);
    } else {
      console.error(`Task DELETE request failed for task ID: ${taskId}`);
    }
  } catch (error) {
    console.error(
      `An error occurred while deleting task with ID ${taskId}:`,
      error,
    );
  }
}

async function getAllTasksAndDelete(unionName, localNumber) {
  try {
    // Construct the URL to get all tasks with the given parameters
    const taskSearchUrl = `https://apijonluca.netlify.app/.netlify/functions/api/tasks?union=${unionName}&localNumber=${localNumber}`;

    // Make an HTTP GET request to retrieve all tasks
    const taskSearchResponse = await axios.get(taskSearchUrl);

    if (taskSearchResponse.status === 200) {
      // Extract the list of tasks from the response
      const tasks = taskSearchResponse.data;

      // Iterate through the tasks and delete each one by its _id
      for (const task of tasks) {
        await deleteTaskById(task._id);
      }
    } else {
      console.error(
        "Task search GET request failed:",
        taskSearchResponse.status,
        taskSearchResponse.data,
      );
      // Handle error cases for the task search GET request
    }
  } catch (error) {
    console.error("An error occurred while deleting tasks:", error);
    // Handle any exceptions here
  }
}


export async function moveToProposal({
  unionName,
  localNumber,
  id,
  refreshSuspectList,
}) {
  try {
    // Step 1: Add history

    // Step 2: Make a PUT request to update the clientType
    await axios.put(
      `https://apijonluca.netlify.app/.netlify/functions/api/unions/${id}`,
      { clientType: "Proposal" },
    );

    // Step 3: Call the `getAllTasksAndDelete` function
    await getAllTasksAndDelete(unionName, localNumber); // Use await to ensure it's completed before continuing

    addHistory({
      id: id,
      note: `Activation set to proposal`,
      reason: ``,
    });
    // Step 4: Call the refreshSuspectList function
    refreshSuspectList();
  } catch (error) {
    // Handle errors here
    console.error("Error occurred:", error);
    // You can choose to rethrow the error or take any other necessary action.
  }
}

export async function moveToClient({
  unionName,
  localNumber,
  id,
  refreshSuspectList,
}) {
  try {
    // Step 1: Add history

    // Step 2: Make a PUT request to update the clientType
    await axios.put(
      `https://apijonluca.netlify.app/.netlify/functions/api/unions/${id}`,
      {
        clientType: "Client",
      },
    );
    // Step 3: Call the `getAllTasksAndDelete` function
    await getAllTasksAndDelete(unionName, localNumber); // Use await to ensure it's completed before continuing

    addHistory({
      id: id,
      note: `Proposal set to client`,
      reason: ``,
    });
    // Step 4: Call the refreshSuspectList function
    refreshSuspectList();
  } catch (error) {
    // Handle errors here
    console.error("Error occurred:", error);
    // You can choose to rethrow the error or take any other necessary action.
  }
}
