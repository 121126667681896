import React, { useEffect, useState, useCallback } from "react";
import { loggedInUser } from "../helpers/localStorage";
import Navbar from "../components/navigation/Navbar";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import EditModal from "../components/modals/EditModal";
import { FaEllipsisH } from "react-icons/fa";

import { deleteLead } from "../helpers/leadActions";
import { handleAssignedRepChange } from "../helpers/strategies/infopak";

const Leads = ({ salesReps }) => {
  // State variables
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const isAdmin = localStorage.getItem("isAdmin") === "true";
  const [isLoading, setIsLoading] = useState(true);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedClientData, setSelectedClientData] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [filterOption, setFilterOption] = useState("all");
  const [leadType, setLeadType] = useState(null); // Initialize with null or a default value
  const [sortOption, setSortOption] = useState("default");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSalesRepFilter, setSelectedSalesRepFilter] = useState("all");
  const [selectedProvinceFilter, setSelectedProvinceFilter] = useState("all"); // New state for province filter



  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const leadTypeValue = queryParams.get("leadType") || "";
    setLeadType(leadTypeValue.charAt(0).toUpperCase() + leadTypeValue.slice(1));
    console.log(leadTypeValue.charAt(0).toUpperCase() + leadTypeValue.slice(1));
  }, [location.search]);

  const fetchData = useCallback(() => {
    if (!leadType) {
      return;
    }

    axios
      .get("https://apijonluca.netlify.app/.netlify/functions/api/unions")
      .then((response) => {
        let suspectData = response.data.filter(
          (item) => item.clientType === leadType,
        );

        // Apply province filtering if a specific province is selected
        if (selectedProvinceFilter !== "all") {
          suspectData = suspectData.filter(
            (item) => item.province === selectedProvinceFilter
          );
        }

        setData(suspectData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, [leadType, selectedProvinceFilter]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setIsLoading(true);
    if (leadType) {
      fetchData(); // Using the memoized function here
    }
  }, [leadType, fetchData]); // Include fetchData in the dependency array

  // Handle actions click (show/hide row actions)
  const handleActionsClick = (row) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [row._id]: !prevSelectedRows[row._id],
    }));
  };

  // Open edit modal
  const openEditModal = (client) => {
    setShowDeleteConfirmation(false);
    setSelectedClientData(client);
    setIsEditModalOpen(true);
  };

  // Refresh suspect list
  const refreshSuspectList = () => {
    fetchData();
  };

  // Handle delete click (show delete confirmation)
  const handleDeleteClick = (row) => {
    setShowDeleteConfirmation(true);
  };

  // Handle delete confirmed (delete lead and refresh list)
  const handleDeleteConfirmed = (id) => {
    deleteLead(id)
      .then(() => {
        setSelectedRows((prevSelectedRows) => ({
          ...prevSelectedRows,
          [id]: false,
        }));
        refreshSuspectList();
      })
      .catch((error) => {
        console.error("Error deleting lead:", error);
      });
  };

  // Handle delete cancelled (hide delete confirmation)
  const handleDeleteCancelled = () => {
    setShowDeleteConfirmation(false);
  };

  // Handle filter change
  const handleFilterChange = (e) => {
    setFilterOption(e.target.value);
  };

  const handleSalesRepFilterChange = (e) => {
    setSelectedSalesRepFilter(e.target.value);
  };

  // Filter the data based on the selected filter options and leadType query parameter
  const filteredData = data.filter((item) => {
    const queryParams = new URLSearchParams(location.search);
    const leadType = queryParams.get("leadType");

    // Check if clientType matches the capitalized leadType query parameter
    const clientTypeMatches =
      item.clientType.toLowerCase() === leadType.toLowerCase();

    // Check if the union parameter contains the search term (case-insensitive)
    const unionMatches = item.union
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    // Check if the assignedRep matches the selected sales rep
    const salesRepMatches =
      selectedSalesRepFilter === "all" ||
      item.assignedRep === selectedSalesRepFilter;


    if (filterOption === "all") {
      return clientTypeMatches && unionMatches && salesRepMatches;
    } else if (filterOption === "none") {
      return (
        clientTypeMatches &&
        unionMatches &&
        (item.assignedRep === "None" || item.assignedRep == null)
      );
    } else if (filterOption === "assigned") {
      return (
        clientTypeMatches &&
        unionMatches &&
        item.assignedRep !== "None" &&
        salesRepMatches
      );
    }

    // Default to returning the item if no conditions match
    return true;
  });

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  // Handle sort change
  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  // Sort the data based on the selected sorting option
  const sortedData = [...filteredData].sort((a, b) => {
    if (sortOption === "a-z") {
      return (
        a.union.localeCompare(b.union) ||
        a.localNumber.localeCompare(b.localNumber)
      );
    } else if (sortOption === "z-a") {
      return (
        b.union.localeCompare(a.union) ||
        b.localNumber.localeCompare(a.localNumber)
      );
    }
    return 0;
  });

  const getBackgroundColor = (assignedRep) => {
    const colorMap = {
      Greg: "bg-red-200",
      Fred: "bg-blue-200",
      Leeanne: "bg-green-200",
      Admin: "bg-yellow-200",
      Edward: "bg-orange-200",
      Quebec:"bg-purple-100",
      None: "bg-white", // Default color if none of the above
    };

    return colorMap[assignedRep] || colorMap["SalesRep7"];
  };

  // Render the table
  const renderTable = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    } else {
      return (
        <table className="min-w-full divide-y divide-gray-200">
          {/* Table header columns */}
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Union
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Local Number
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Office Phone Number
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Email
          </th>
          {isAdmin && (
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Assigned Rep
            </th>
          )}
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Actions
          </th>

          {/* Table body */}
          <tbody className="divide-y divide-gray-600">
            {sortedData
              .filter(
                (item) =>
                  (item.assignedRep === loggedInUser() && !isAdmin) || isAdmin,
              )
              .map((item) => (
                <tr
                  key={item._id}
                  className={getBackgroundColor(item.assignedRep)}
                >
                  {/* Table data cells */}
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={`/lead/${item._id}`}
                    >
                      {item.union}
                    </Link>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={`/lead/${item._id}`}
                    >
                      {item.localNumber}
                    </Link>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={`/lead/${item._id}`}
                    >
                      {item.officePhoneNumber}
                    </Link>
                  </td>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={`/lead/${item._id}`}
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.email}
                    </td>
                  </Link>
                  {isAdmin && (
                    <td className="px-6 py-4 whitespace-nowrap">
                      <select
                        value={item.assignedRep}
                        onChange={(e) =>
                          handleAssignedRepChange(e, item, refreshSuspectList)
                        }
                      >
                        <option value="None">None</option>
                        {salesReps.map((rep) => (
                          <option key={rep} value={rep}>
                            {rep}
                          </option>
                        ))}
                      </select>
                    </td>
                  )}
                  <td className="px-6 py-4 whitespace-nowrap">
                    <button
                      className="text-zinc-600 hover:text-indigo-900"
                      onClick={() => handleActionsClick(item)}
                    >
                      <FaEllipsisH />
                    </button>
                    {selectedRows[item._id] && (
                      <div className="fixed inset-0 flex items-center justify-center z-10">
                        <div className="bg-white p-4 border border-gray-300 shadow-md rounded-lg">
                          {showDeleteConfirmation ? (
                            <>
                              <div className="mb-2 font-bold">
                                Are you sure you want to delete {item.union}{" "}
                                {item.localNumber}?
                              </div>
                              <hr className="my-2" />
                              <div className="flex gap-4">
                                <button
                                  className="bg-red-500 text-white py-1 px-2 rounded-lg"
                                  onClick={handleDeleteCancelled}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="bg-blue-500 text-white py-1 px-2 rounded-lg"
                                  onClick={() =>
                                    handleDeleteConfirmed(item._id)
                                  }
                                >
                                  Confirm Delete
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="mb-2 font-bold">
                                Actions for Row{" "}
                                {`${item.union} ${item.localNumber}`}
                              </div>
                              <hr className="my-2" />
                              <ul>
                                <li className="py-2">
                                  <Link
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to={`/lead/${item._id}`}
                                  >
                                    Lead Page
                                  </Link>
                                </li>
                                <hr className="my-2" />
                                <button onClick={() => openEditModal(item)}>
                                  <li className="py-2">Edit</li>
                                </button>
                                <hr className="my-2" />
                                <button onClick={() => handleDeleteClick(item)}>
                                  <li className="py-2">Delete</li>
                                </button>
                              </ul>
                              <button
                                className="mt-4 bg-indigo-500 text-white px-3 py-1 rounded-lg"
                                onClick={() => setSelectedRows({})}
                              >
                                Close
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      );
    }
  };

  return (
    <div>
      <Navbar />
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">
          {leadType
            ? `${leadType.charAt(0).toUpperCase() + leadType.slice(1)} Leads`
            : "Leads"}
        </h1>
        <div className="flex gap-x-16">
          {isAdmin && (
            <div className="mb-4">
              <label htmlFor="filter" className="block mb-2">
                Filter Settings
              </label>
              <select
                id="filter"
                value={filterOption}
                onChange={handleFilterChange}
                className="p-2 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-400"
              >
                <option value="all">Show All</option>
                <option value="none">Unassigned</option>
                <option value="assigned">Assigned</option>
              </select>
            </div>
          )}

          <div className="mb-4">
            <label htmlFor="sort" className="block mb-2">
              Sort Settings
            </label>
            <select
              id="sort"
              value={sortOption}
              onChange={handleSortChange}
              className="p-2 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-400"
            >
              <option value="default">Default</option>
              <option value="a-z">A-Z</option>
              <option value="z-a">Z-A</option>
            </select>
          </div>

          <div className="mb-4">
            <label htmlFor="search" className="block mb-2">
              Search by Union
            </label>
            <input
              type="text"
              id="search"
              value={searchTerm}
              onChange={handleSearchChange}
              className="p-2 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-400"
              placeholder="Enter Union"
            />
          </div>

          {isAdmin && (
            <div className="mb-4">
              <label htmlFor="salesRepFilter" className="block mb-2">
                Sales Rep
              </label>
              <select
                id="salesRepFilter"
                value={selectedSalesRepFilter}
                onChange={handleSalesRepFilterChange}
                className="p-2 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-400"
              >
                <option value="all">All Sales Reps</option>
                {salesReps.map((rep) => (
                  <option key={rep} value={rep}>
                    {rep}
                  </option>
                ))}
              </select>
            </div>
          )}

<div className="mb-4">
    <label htmlFor="provinceFilter" className="block mb-2">
      Province
    </label>
    <select
      id="provinceFilter"
      value={selectedProvinceFilter}
      onChange={(e) => setSelectedProvinceFilter(e.target.value)}
      className="p-2 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-400"
    >
      <option value="all">All Provinces</option>
      <option value="AB">Alberta</option>
      <option value="BC">British Columbia</option>
      <option value="MB">Manitoba</option>
      <option value="NB">New Brunswick</option>
      <option value="NL">Newfoundland and Labrador</option>
      <option value="NS">Nova Scotia</option>
      <option value="ON">Ontario</option>
      <option value="PE">Prince Edward Island</option>
      <option value="QC">Quebec</option>
      <option value="SK">Saskatchewan</option>
      <option value="NT">Northwest Territories</option>
      <option value="NU">Nunavut</option>
      <option value="YT">Yukon</option>
    </select>
  </div>

        </div>

        {renderTable()}
        {isEditModalOpen && (
          <EditModal
            closeModal={() => setIsEditModalOpen(false)}
            clientData={selectedClientData}
            refreshSuspectList={refreshSuspectList}
          />
        )}
      </div>
    </div>
  );
};

export default Leads;
