import React, { useState } from "react";
import { formatDate, formatSpelledDate } from "../../helpers/formatDate";
import { getCurrentTimeInTimeZone } from "../../helpers/formatTime";
import { FaPhone, FaList, FaEnvelope } from "react-icons/fa";
import Info from "../../components/Info";
import ScriptModal from "../modals/ScriptModal";
import LeadPageHistory from "./LeadPageHistory";
import { deleteTask } from "../../helpers/taskActions";
export default function LeadPageContent({
  data,
  setIsNoContactModalOpen,
  setIsMakeProspectModalOpen,
  setIsNoModalOpen,
  setIsYesModalOpen,
  setIsRescheduleModalOpen,
  setIsWaitModalOpen,
  setIsMoveToCallCalendarModalOpen,
  handleEditModalOpen,
  refreshTasks,
  tasks,
}) {
  const [selectedTab, setSelectedTab] = useState("contactInfo1");
  const [isScriptModalOpen, setIsScriptModalOpen] = useState(false);
  const [scriptText, setScriptText] = useState("");
  const [scriptVoiceMail, setScriptVoiceMail] = useState("");
  const [scriptSubject, setScriptSubject] = useState("");

  const openScriptModal = ({ message, subject, voiceMail }) => {
    setIsScriptModalOpen(true);
    // Set the initial script text when opening the modal
    setScriptText(message);
    setScriptVoiceMail(voiceMail);
    setScriptSubject(subject);
  };
  const closeScriptModal = () => {
    setIsScriptModalOpen(false);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <div className="mb-4">
      {data ? (
        <div className="">
          {isScriptModalOpen && (
            <ScriptModal
              message={scriptText}
              subject={scriptSubject}
              voicemail={scriptVoiceMail}
              closeScriptModal={closeScriptModal}
            />
          )}
          <div className="flex gap-2 px-4 py-4 bg-gray-100">
            <h1 className="text-2xl font-bold p-1 ">
              {data.union}
              <span className="font-normal">
                {" "}
                {data.contactName}{" "}
                {data.contactJobTitle && `(${data.contactJobTitle})`}
              </span>
            </h1>

            <button
              className="text-lg px-2 text-white bg-blue-500 rounded-md"
              onClick={handleEditModalOpen}
            >
              Edit
            </button>
            <button className="text-lg px-2 text-white bg-green-500 rounded-md">
              <a
                href={`tel:${data.primaryContact === 1 ? data.contactNumber1 || data.officePhoneNumber : data.alternateContactNumber1 || data.officePhoneNumber}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Call
              </a>
            </button>

            <div className="m-2 flex align-middle">
              <div className="bg-yellow-500 h-2 w-2 rounded-full align-middle self-center mr-2"></div>
              <p className="font-bold text-lg">
                {data.clientType}
                <span className="capitalize"> ({data.strategy} Strategy) </span>
              </p>
            </div>

            {data.timeZone && (
              <div className="self-center mr-2">
                <p className="font-bold text-lg">
                  Business's Current Time:{" "}
                  {getCurrentTimeInTimeZone(data.timeZone)}
                </p>
              </div>
            )}
          </div>

          <div className="flex gap-x-2 bg-gray-100 rounded-md overflow-hidden px-4">
            <button
              className={`tab-button ${selectedTab === "contactInfo1" ? "active font-bold  bg-white" : "text-gray-500 bg-gray-100"}  border-t-2 border-l-2 border-r border-b-0 rounded-tl-md px-4 py-2`}
              onClick={() => handleTabChange("contactInfo1")}
            >
              Contact Info 1
            </button>
            <button
              className={`tab-button ${selectedTab === "contactInfo2" ? "active font-bold  bg-white" : "text-gray-500 bg-gray-100"}  border-t-2 border-l-2 border-r border-b-0 rounded-tl-md px-4 py-2`}
              onClick={() => handleTabChange("contactInfo2")}
            >
              Contact Info 2
            </button>
            <button
              className={`tab-button ${selectedTab === "unionInfo" ? "active font-bold  bg-white" : "text-gray-500  bg-gray-100"} border-t-2 border-r-2 border-l border-b-0 rounded-tr-md px-4 py-2`}
              onClick={() => handleTabChange("unionInfo")}
            >
              Union Info
            </button>
            <button
              className={`tab-button ${selectedTab === "notes" ? "active font-bold  bg-white" : "text-gray-500  bg-gray-100"} border-t-2 border-r-2 border-l border-b-0 rounded-tr-md px-4 py-2`}
              onClick={() => handleTabChange("notes")}
            >
              Notes
            </button>
            <button
              className={`tab-button ${selectedTab === "history" ? "active font-bold  bg-white" : "text-gray-500  bg-gray-100"} border-t-2 border-r-2 border-l border-b-0 rounded-tr-md px-4 py-2`}
              onClick={() => handleTabChange("history")}
            >
              History
            </button>
          </div>

          {selectedTab === "contactInfo1" && (
            <div className="bg-white px-4">
              <p className="title-2">Contact 1</p>

              <div className="grid grid-cols-5 gap-4">
                <Info title={`Full Name`} subtitle={data.contactName} />
                <Info title={`Job Title`} subtitle={data.contactJobTitle} />
                <Info
                  title={`Cell Phone Number`}
                  subtitle={data.contactNumber1}
                />
                <Info
                  title={`Home Phone Number`}
                  subtitle={data.contactNumber2}
                />
                <Info title={`Email`} subtitle={data.contactEmail} />
                <Info title={`Gender`} subtitle={data.contactGender} />
                <Info
                  title={`Birthday`}
                  subtitle={formatSpelledDate(data.contactBirthDay)}
                />
              </div>
            </div>
          )}

          {selectedTab === "contactInfo2" && (
            <div className="bg-white px-4">
              <p className="title-2">Contact 2</p>

              <div className="grid grid-cols-5 gap-4">
                <Info
                  title={`Full Name`}
                  subtitle={data.alternateContactName}
                />
                <Info
                  title={`Job Title`}
                  subtitle={data.alternateContactJobTitle}
                />
                <Info
                  title={`Cell Phone Number`}
                  subtitle={data.alternateContactNumber1}
                />
                <Info
                  title={`Home Phone Number`}
                  subtitle={data.alternateContactNumber2}
                />
                <Info title={`Email`} subtitle={data.alternateContactEmail} />
                <Info title={`Gender`} subtitle={data.alternateContactGender} />
                <Info
                  title={`Birthday`}
                  subtitle={formatSpelledDate(data.alternateContactBirthDay)}
                />
              </div>
            </div>
          )}

          {selectedTab === "unionInfo" && (
            <div className="bg-white px-4">
              <p className="title-2">Local Info</p>

              <div className="grid grid-cols-5 gap-4">
                <Info
                  title={`Office Phone Number`}
                  subtitle={data.officePhoneNumber}
                />
                <Info title={`Email`} subtitle={data.email} />
                <Info title={`Website`} subtitle={data.website} />
                <Info title={`Fax Number`} subtitle={data.faxNumber} />
                <Info title={`City`} subtitle={data.city} />
              </div>

              <div className="grid grid-cols-5 gap-4">
                <Info title={`Province`} subtitle={data.province} />
                <Info title={`Postal Code`} subtitle={data.postal} />
                <Info title={`Address`} subtitle={data.address} />
                <Info title={`Member Size`} subtitle={data.memberSize} />
                <Info title={`Revenue`} subtitle={data.revenue} />
              </div>

              <div className="grid grid-cols-5 gap-4">
                <Info title={`Election Cycle`} subtitle={data.electionCycle} />
                <Info title={`Election Date`} subtitle={data.electionDate} />
                <Info
                  title={`Bargaining Units`}
                  subtitle={data.bargainingUnits}
                />
                <Info title={`Time Zone`} subtitle={data.timeZone} />
              </div>
            </div>
          )}
          {selectedTab === "notes" && (
            <div className="bg-white px-4">
              <p className="title-2">Notes</p>

              <div>
                <Info title={`Notes`} subtitle={data.notes} />
              </div>
            </div>
          )}

          {(selectedTab === "unionInfo" ||
            selectedTab === "contactInfo1" ||
            selectedTab === "contactInfo2") && (
            <div className="bg-white px-4">
              <p className="title-2">Tasks</p>
              <div
                className="flex gap-x-2  overflow-x-auto"
                style={{ maxWidth: "100vw" }}
              >
                {tasks
                  .sort(
                    (task1, task2) =>
                      new Date(task1.date) - new Date(task2.date),
                  ) // Sort tasks by date in ascending order
                  .map((task) => (
                    <div className="rounded-md bg-white w-96 mb-2 border-solid border-2 border-indigo-600">
                      <div
                        className={`
              ${task.clientType === "Suspect" && `bg-suspect`}
              ${task.clientType === "Prospect" && `bg-prospect`}
              ${task.clientType === "Activation" && `bg-activation`}
              ${task.clientType === "Proposal" && `bg-proposal`}
              
              p-2`}
                      >
                        <p className="font-black text-md">
                          {formatDate(task.date)}{" "}
                          {task.completed && (
                            <span className="font-bold underline text-red-600">
                              Completed
                            </span>
                          )}
                        </p>
                      </div>
                      <div className=" p-2">
                        <p
                          className={`font-bold text-xl ${task.completed && "line-through"}`}
                        >
                          {task.title}
                        </p>

                        {task.startTime && (
                          <p>{`${task.startTime} ${task.endTime && `-`} ${task.endTime}`}</p>
                        )}
                        {/* {data.primaryContact!==2?
             <p>{data.contactName} {data.contactJobTitle&&`(${data.contactJobTitle})`}</p>
             :
             <p>{data.alternateContactName} {data.alternateContactJobTitle&&`(${data.alternateContactJobTitle})`}</p>
            } */}
                        <a
                          href={`tel:${data.primaryContact === 1 ? data.contactNumber1 || data.officePhoneNumber : data.alternateContactNumber1 || data.officePhoneNumber}`}
                        >
                          <div className="w-96">
                            <div className="flex items-center gap-x-2">
                              <FaPhone />{" "}
                              {data.primaryContact === 1
                                ? data.contactNumber1 || data.officePhoneNumber
                                : data.alternateContactNumber1 ||
                                  data.officePhoneNumber}
                            </div>
                            <a
                              href={`mailto:${data.primaryContact === 1 ? data.contactEmail || data.email : data.alternateContactEmail || data.email}`}
                            >
                              {data.primaryContact === 1
                                ? data.contactEmail || data.email
                                : data.alternateContactEmail || data.email}
                            </a>
                          </div>
                        </a>
                        <p>{task.description}</p>
                      </div>
                      {
                        task.script.length > 0 && (
                          <>
                            {/* <a target='_blank'  rel="noreferrer" href={`https://outlook.office.com/mail/deeplink/compose?to=${task.contactEmail}&subject=${encodeURIComponent(task.script[0].subject)}&body=${encodeURIComponent("<p>Hello world</p>")}`} className='flex items-center gap-x-2'><FaEnvelope/>Send Email</a> */}

                            {task.script[0].isEmail ? (
                              <button className="m-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`https://outlook.office.com/mail/deeplink/compose?to=${data.primaryContact === 1 ? data.contactEmail || data.email : data.alternateContactEmail || data.email}&subject=${encodeURIComponent(task.script[0].subject)}&body=${encodeURIComponent(task.script[0].message)}`}
                                  className="flex items-center gap-x-2"
                                >
                                  <FaEnvelope />
                                  Send Email
                                </a>
                              </button>
                            ) : (
                              <button
                                className="m-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 my-2"
                                onClick={() =>
                                  openScriptModal({
                                    message: task.script[0].message,
                                    subject: task.script[0].subject,
                                    voiceMail: task.script[0].voiceMail,
                                  })
                                }
                              >
                                <span className="flex items-center gap-x-2">
                                  <FaList />
                                  See Script
                                </span>
                              </button>
                            )}
                            <div className="flex gap-x-2 m-2">
                              {task.title === "Make Wait Call" && (
                                <>
                                  <button
                                    className="bg-yellow-500 button"
                                    onClick={() =>
                                      deleteTask({
                                        id: task._id,
                                        refreshTasks: refreshTasks,
                                      })
                                    }
                                  >
                                    Don't Wait
                                  </button>
                                  <button
                                    className="bg-red-500 button"
                                    onClick={() => setIsWaitModalOpen(true)}
                                  >
                                    Activate Wait Strategy
                                  </button>
                                </>
                              )}
                            </div>
                          </>
                        )

                        //
                      }
                    </div>
                  ))}
              </div>

              {(data.strategy === "infopak" || !data.strategy) && (
                <>
                  {data.clientType === "Suspect" ? (
                    <div className="flex gap-x-2">
                      <button
                        className="bg-green-500 button"
                        onClick={() => setIsMakeProspectModalOpen(true)}
                      >
                        Yes
                      </button>
                      <button
                        className="bg-red-500 button"
                        onClick={() => setIsNoModalOpen(true)}
                      >
                        No
                      </button>
                      <button
                        className="bg-yellow-500 button"
                        onClick={() => setIsWaitModalOpen(true)}
                      >
                        Wait
                      </button>
                      <button
                        className="bg-zinc-300 button"
                        onClick={() => setIsNoContactModalOpen(true)}
                      >
                        No Contact
                      </button>
                    </div>
                  ) : (
                    <div className="flex gap-x-2">
                      <button
                        className="bg-green-500 button"
                        onClick={() => setIsYesModalOpen(true)}
                      >
                        Yes
                      </button>
                      <button
                        className="bg-red-500 button"
                        onClick={() => setIsNoModalOpen(true)}
                      >
                        No
                      </button>
                      <button
                        className="bg-yellow-500 button"
                        onClick={() => setIsWaitModalOpen(true)}
                      >
                        Wait
                      </button>
                      <button
                        className="bg-zinc-300 button"
                        onClick={() => setIsNoContactModalOpen(true)}
                      >
                        No Contact
                      </button>
                    </div>
                  )}
                </>
              )}
              {/* change buttons if the client is a proposal */}
              {data.strategy === "appointment" && (
                <>
                  {data.clientType !== "Proposal" ? (
                    data.clientType !== "Client" ? (
                      <div className="flex gap-x-2">
                        <button
                          className="bg-green-500 button"
                          onClick={() => setIsYesModalOpen(true)}
                        >
                          Yes
                        </button>
                        <button
                          className="bg-red-500 button"
                          onClick={() => setIsNoModalOpen(true)}
                        >
                          No
                        </button>
                        <button
                          className="bg-yellow-500 button"
                          onClick={() => setIsWaitModalOpen(true)}
                        >
                          Wait
                        </button>
                        <button
                          className="bg-zinc-300 button"
                          onClick={() => setIsNoContactModalOpen(true)}
                        >
                          No Contact
                        </button>
                        <button
                          className="bg-blue-300 button"
                          onClick={() => setIsRescheduleModalOpen(true)}
                        >
                          Reschedule Appointment
                        </button>
                      </div>
                    ) : (
                      <h2 className="my-2">No tasks. This is a client now</h2>
                    )
                  ) : (
                    <>
                      <h2 className="my-2">Wait for the proposal's response</h2>
                      <div className="flex gap-x-2">
                        <button
                          className="bg-red-500 button"
                          onClick={() => setIsNoModalOpen(true)}
                        >
                          No
                        </button>
                        <button
                          className="bg-green-500 button"
                          onClick={() => setIsMoveToCallCalendarModalOpen(true)}
                        >
                          Yes
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}

              {data.strategy === "active" && (
                <div className="flex gap-x-2">
                  <button
                    className="bg-green-500 button"
                    onClick={() => setIsYesModalOpen(true)}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-red-500 button"
                    onClick={() => setIsNoModalOpen(true)}
                  >
                    No
                  </button>
                  <button
                    className="bg-yellow-500 button"
                    onClick={() => setIsWaitModalOpen(true)}
                  >
                    Wait
                  </button>
                  <button
                    className="bg-zinc-300 button"
                    onClick={() => setIsNoContactModalOpen(true)}
                  >
                    No Contact
                  </button>
                </div>
              )}

              {data.strategy === "wait" && (
                <div className="flex gap-x-2">
                  <button
                    className="bg-green-500 button"
                    onClick={() => setIsYesModalOpen(true)}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-red-500 button"
                    onClick={() => setIsNoModalOpen(true)}
                  >
                    No
                  </button>
                  <button
                    className="bg-yellow-500 button"
                    onClick={() => setIsWaitModalOpen(true)}
                  >
                    Wait
                  </button>
                  <button
                    className="bg-zinc-300 button"
                    onClick={() => setIsNoContactModalOpen(true)}
                  >
                    No Contact
                  </button>
                </div>
              )}
            </div>
          )}
          {selectedTab === "history" && <LeadPageHistory data={data} />}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
