import React,{useState} from 'react'

export default function MonthlyReportContent({data, salesReps, setSelectedRep, selectedRep, isAdmin}) {

    
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedYear, setSelectedYear] = useState(currentYear);


  

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i);

    const filteredData = data.filter(item => {
        const itemDate = new Date(item.date);
        return itemDate.getFullYear() === selectedYear && itemDate.getMonth() + 1 === selectedMonth;
      });
    
      const userSpecificData = filteredData.flatMap(item => 
        item.users.filter(user => selectedRep === '' || user.name.toLowerCase() === selectedRep.toLowerCase()).map(user => ({
          ...user,
          date: item.date
        }))
      );
    
      const totalStats = userSpecificData.reduce((acc, user) => {
        acc.callsMade += (user.callsMade || 0);
        acc.emailsSent += (user.emailsSent || 0);
        acc.timesKicked += (user.timesKicked || 0);
        acc.demosBooked += (user.demosBooked || 0);
        acc.packagesSent += (user.packagesSent || 0);
    
    
        
        return acc;
      }, { callsMade: 0, emailsSent: 0, timesKicked: 0, packagesSent: 0, demosBooked:0, totalKPI:0 });
    
      const calculateKPI = user => {
        const dailyKPI = (user.callsMade || 0) + 
                         (user.emailsSent || 0) + 
                         (user.packagesSent || 0) + 
                         (user.demosBooked || 0);
        const dailyPercentage = (dailyKPI/50)*100
    
        return {dailyKPI, dailyPercentage};
    }
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {isAdmin&&<div>
            <label className="block text-sm font-medium text-gray-700">Sales Representative:</label>
            <select
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              value={selectedRep}
              onChange={(e) => setSelectedRep(e.target.value)}>

              {salesReps.map((rep, index) => (
                <option key={index} value={rep}>{rep}</option>
              ))}
            </select>
          </div>}

          <div>
            <label className="block text-sm font-medium text-gray-700">Month:</label>
            <select
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(parseInt(e.target.value))}>
              {months.map((month, index) => (
                <option key={month} value={index + 1}>{month}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Year:</label>
            <select
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              value={selectedYear}
              onChange={(e) => setSelectedYear(parseInt(e.target.value))}>
              {years.map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>

          {/* Data Display */}
          <div className="col-span-4">

          <div className="">
         
  <div className='flex gap-x-8 items-center bg-zinc-100 py-8 mb-2 justify-center'>
    <p><span className='font-bold'>Calls Made: </span>{totalStats.callsMade}</p>
    <p><span className='font-bold'>Emails Sent: </span>{totalStats.emailsSent}</p>
    <p><span className='font-bold'>Demos Booked: </span>{totalStats.demosBooked}</p>
    <p><span className='font-bold'>Packages Sent: </span>{totalStats.packagesSent}</p>
    <p><span className='font-bold'>Times Kicked: </span>{totalStats.timesKicked}</p>
    <p><span className='font-bold'>Times Kicked: </span>{totalStats.totalKPI}</p>
  </div>
</div>




            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3">Date</th>

                  <th scope="col" className="px-6 py-3">Log In Time</th>
                  <th scope="col" className="px-6 py-3">Calls Made</th>
                  <th scope="col" className="px-6 py-3">Emails Sent</th>
                  <th scope="col" className="px-6 py-3">Packages Sent</th>
                  <th scope="col" className="px-6 py-3">Demos Booked</th>
                  <th scope="col" className="px-6 py-3">Times Kicked</th>
                  <th scope="col" className="px-6 py-3">Daily KPI</th>
                  <th scope="col" className="px-6 py-3">Daily KPI Percentage</th>
                </tr>
              </thead>
              <tfoot className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr className="text-xs text-gray-700 uppercase bg-gray-50">
                  <th scope="col" className="px-6 py-3">Total</th>

                  <th scope="col" className="px-6 py-3"></th>
                  <th scope="col" className="px-6 py-3">{totalStats.callsMade}</th>
                  <th scope="col" className="px-6 py-3">{totalStats.emailsSent}</th>
                  <th scope="col" className="px-6 py-3">{totalStats.packagesSent}</th>
                  <th scope="col" className="px-6 py-3">{totalStats.demosBooked}</th>
                  <th scope="col" className="px-6 py-3">{totalStats.timesKicked}</th>
                  <th scope="col" className="px-6 py-3">{totalStats.totalKPI}</th>
                  <th scope="col" className="px-6 py-3">{}</th>
                </tr>
              </tfoot>
              <tbody>
                {userSpecificData.map((user, index) => (
                  <tr key={index} className={`border-b ${calculateKPI(user).dailyPercentage<65&&'bg-red-100'}`}>
                    <td className="px-6 py-4">{user.date}</td>
   
                    <td className="px-6 py-4">{user.logInTime}</td>
                    <td className="px-6 py-4">{user.callsMade || 0}</td>
                    <td className="px-6 py-4">{user.emailsSent || 0}</td>
                    <td className="px-6 py-4">{user.packagesSent || 0}</td>
                    <td className="px-6 py-4">{user.demosBooked || 0}</td>
                    <td className="px-6 py-4">{user.timesKicked||0}</td>
                    <td className={`px-6 py-4 `}>{calculateKPI(user).dailyKPI}/50</td>
                    <td className={`px-6 py-4 `}>{Math.round(calculateKPI(user).dailyPercentage)}%</td>
                  </tr>
                ))}
              </tbody>

            </table>
          </div>
        </div>
  )
}
