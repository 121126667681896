import React from "react";
import Modal from "./Modal";

import { RedButton, GreenButton } from "../buttons/button";

import { moveToProposal } from "../../helpers/strategies/appointment";

export default function NewClientModal({
  closeModal,
  data,
  refreshSuspectList,
}) {
  const confirmClient = ({ unionName, localNumber, id }) => {
    closeModal();

    moveToProposal({ unionName, localNumber, id, refreshSuspectList }); // Leaving note blank as an empty string
  };

  return (
    <Modal closeModal={closeModal} fullModal={false}>
      <h2 className="text-xl font-bold">Did you complete the presentation?</h2>

      <div className="flex gap-x-2 mt-16 justify-end">
        <RedButton action={closeModal}>Cancel</RedButton>
        <GreenButton
          action={() =>
            confirmClient({
              unionName: data.union,
              localNumber: data.localNumber,
              id: data._id,
            })
          }
        >
          Mark presentation as complete
        </GreenButton>
      </div>
    </Modal>
  );
}
