import React, { useState, useEffect } from "react";
import { formatDate } from "../helpers/formatDate";
import { Link } from "react-router-dom";
import axios from "axios";
import { completeTask } from "../helpers/taskActions";

export default function PrintTaskComponent({ task, refreshTasks }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const apiURL = `https://apijonluca.netlify.app/.netlify/functions/api/unions?union=${task?.union}&localNumber=${task?.localNumber}&includeHistory=true`;

  const [unionData, setUnionData] = useState({});
  useEffect(() => {
    axios
      .get(apiURL)
      .then((response) => {
        setUnionData(response.data[0]);
      })
      .catch((error) => {
        console.error("An error occurred while fetching union data:", error);
      });
  }, [apiURL]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmCompleteTask = (id, refreshTasks) => {
    completeTask(id, refreshTasks);
    closeModal();
  };

  const taskNotCompleted = () => {
    closeModal();
  };

  return (
    <>
      {!task?.completed && (
        <div key={task?._id}>
          <Link to={`/lead/${task?.link}`}>
            <h2 className="font-bold text-xl mb-2">
              {task?.union} {task?.localNumber}
            </h2>
          </Link>
          <div>
            {unionData && (
              <div className="flex">
                <div className="flex flex-row gap-x-12 px-2">
                  <div>
                    <p className="font-bold">Local Office Number: </p>
                    {unionData?.officePhoneNumber}
                  </div>
                  <div>
                    <p className="font-bold">Local Email: </p>
                    {unionData?.email}
                  </div>
                </div>
              <div className="flex-col gap-4">
                {unionData?.contactName && (
                  <div className="flex flex-row gap-x-4 font-bold mb-2">
                    <div>
                      Contact Name:{" "}
                      <p className="font-normal">{unionData?.contactName}</p>
                    </div>
                    {unionData?.contactNumber1&&<div>
                      Contact Number:{" "}
                      <p className="font-normal">{unionData?.contactNumber1}</p>
                    </div>}
                    {unionData?.contactNumber2&&<div>
                      Contact Email:{" "}
                      <p className="font-normal">{unionData?.contactEmail}</p>
                    </div>}
                  </div>
                )}

                {/* {unionData?.alternateContactName && (
                  <div className="flex flex-row gap-x-4 font-bold mb-2">
                    <div>
                      Contact Name:{" "}
                      <p className="font-normal">
                        {unionData?.alternateContactName}
                      </p>
                    </div>
                    <div>
                      Contact Number:{" "}
                      <p className="font-normal">
                        {unionData?.alternateContactNumber1}
                      </p>
                    </div>
                    <div>
                      Contact Email:{" "}
                      <p className="font-normal">
                        {unionData?.alternateContactEmail}
                      </p>
                    </div>
                  </div>
                )} */}
              </div>
              </div>
            )}
            
          </div>
          

          <div className="bg-white rounded-md p-1 flex gap-x-2 mb-2">
            <h2 className="font-bold">To Do:</h2>
            <p className="text-black">
              {task?.title && task?.title}. {task?.description && task?.description}
            </p>
          </div>

          {unionData?.history &&<div className=" mb-2">
            <h2 className="font-bold self-start ">Recent History</h2>
            <div className="flex flex-wrap gap-x-2">
              {
                unionData?.history.length > 0 &&
                unionData?.history
                  .reverse()
                  .slice(0, 2)
                  .map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col md:flex-row gap-x-2"
                    >
                      <p className="text-sm text-gray-600">
                        {item.note}{" "}
                        <span className="font-light">
                          ({formatDate(item.date)})
                        </span>
                      </p>
                    </div>
                  ))}
            </div>
          </div>
}

          <hr />

          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
              <div className="bg-white p-4 rounded-lg shadow-lg z-50">
                <p className="mb-4">Has this task been completed?</p>
                <div className="flex justify-end">
                  <button
                    className="mr-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                    onClick={() => confirmCompleteTask(task?._id, refreshTasks)}
                  >
                    Yes
                  </button>
                  <button
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                    onClick={() => taskNotCompleted()}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
