import React from "react";
import Modal from "./Modal";

import { RedButton, GreenButton } from "../buttons/button";

import { moveToClient } from "../../helpers/strategies/appointment";

export default function MoveToCallCalendarModal({
  closeModal,
  data,
  refreshSuspectList,
}) {
  const confirmClient = ({ unionName, localNumber, id }) => {
    closeModal();

    moveToClient({ unionName, localNumber, id, refreshSuspectList, data }); // Leaving note blank as an empty string
  };

  return (
    <Modal closeModal={closeModal} fullModal={false}>
      <h2 className="text-xl font-bold">
        Do you want to make this proposal a client?
      </h2>

      <div className="flex gap-x-2 mt-16 justify-end">
        <RedButton action={closeModal}>Cancel</RedButton>
        <GreenButton
          action={() =>
            confirmClient({
              unionName: data.union,
              localNumber: data.localNumber,
              id: data._id,
            })
          }
        >
          Yes
        </GreenButton>
      </div>
    </Modal>
  );
}
