import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { increaseCount } from "./userActionHistory";

export async function createNewTask(data) {
  const taskUrl = `https://apijonluca.netlify.app/.netlify/functions/api/tasks/`;
  const taskData = {
    _id: uuidv4(),
    completed: false,
    completable: true,
    date: data.date,
    __v: 0,
    description: data.description,
    title: data.title,
    localNumber:data.localNumber||'',
    union:data.union||'',
    clientType:'custom',
    assignedRep: data.assignedRep, // Ensure this matches the field in your data object
  };

  try {
    // Use axios to send a POST request
    const response = await axios.post(taskUrl, taskData);
    console.log('Task created:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating task:', error);
    throw error; // Rethrow the error for handling by the caller
  }
}

export function deleteTask({ id, refreshTasks }) {
  const taskDeleteUrl = `https://apijonluca.netlify.app/.netlify/functions/api/tasks/${id}`;

  axios
    .delete(taskDeleteUrl)
    .then((response) => {
      // Assuming a successful response has a status code of 200
      if (response.status === 200) {
        console.log(`Task with ID ${id} has been deleted.`);
        window.location.reload(); // Reload the page after successful deletion
      } else {
        console.error(
          `Failed to delete task with ID ${id}. Status: ${response.status}`,
        );
      }
    })
    .catch((error) => {
      // Handle errors
      console.error(`Error deleting task with ID ${id}: ${error.message}`);
    });
  refreshTasks(); // Refresh tasks after deletion (optional, depending on your use case)
}

export async function changeTaskUnionLocal({ unionData, id }) {
  try {
    const response = await axios.get(
      "https://apijonluca.netlify.app/.netlify/functions/api/tasks",
    );

    if (response.status === 200) {
      const tasks = response.data;

      // Filter tasks based on the 'link' property
      const matchingTasks = tasks.filter((task) => task.link === id);

      // Log the number of matching tasks
      console.log(
        `Found ${matchingTasks.length} tasks with the same link as ${id}`,
      );

      // Update 'union' and 'local' properties for each matching task
      const updateTasksPromises = matchingTasks.map((task) => {
        const updatedTask = {
          ...task,
          union: unionData.union,
          localNumber: unionData.localNumber,
          timeZone: unionData.timeZone,
        };

        // Make a PUT request to update the task
        return axios.put(
          `https://apijonluca.netlify.app/.netlify/functions/api/tasks/${task._id}`,
          updatedTask,
        );
      });

      // Wait for all update requests to complete
      await Promise.all(updateTasksPromises);

      console.log("Tasks updated successfully.");
    } else {
      console.error("Failed to fetch tasks:", response.statusText);
    }
  } catch (error) {
    console.error("Error while fetching or updating tasks:", error.message);
  }
}

export async function completeTask({ id, reloadFunction, property, rep }) {
  try {
    //increase calls, emails or packages
    increaseCount(rep, property);

    // Define the data to send in the PUT request
    const data = {
      completed: true, // Set completed to true
    };

    // Construct the URL to update the task with the given ID
    const taskUpdateUrl = `https://apijonluca.netlify.app/.netlify/functions/api/tasks/${id}`;

    // Send the PUT request using Axios
    const response = await axios.put(taskUpdateUrl, data);

    // Check if the request was successful
    if (response.status === 200) {
      // Task has been completed successfully
      console.log("Task completed successfully");

      // Refresh the suspect list or perform any other action you need

      reloadFunction && reloadFunction();
      return true;
    } else {
      // Handle any other response status codes or errors
      console.error("Failed to complete task:", response);
      return false;
    }
  } catch (error) {
    // Handle any network or other errors
    console.error("Error completing task:", error);
    return false;
  }
}
