import { findLoggedInUser } from "../localStorage";
import "./phoneScript.css";
import { formatTimeToLocalTime } from "../formatTime";
import { formatSpelledDate } from "../formatDate";

export const initialCall = (unionData, assignedRep) => {
  const salesRepName = findLoggedInUser()
    ? findLoggedInUser(assignedRep).firstname
    : "[Your Name]";
  const phoneNumber = findLoggedInUser()
    ? findLoggedInUser(assignedRep).phonenumber
    : "number";

  const phoneTemplate = {
    subject: "Initial Phone Call",
    isEmail: false,
    message: `<div>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Begin the call with a warm greeting and introduction]
            </p>
            <p>
                <span style="font-weight: bold;">${assignedRep}: </span> Hello, <span style="textDecoration: underline">${unionData?.contactName || "[business owner]"}</span>, my name is ${salesRepName}, and I'm calling on behalf of Pay 321. How are you today?
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Wait for their response]
            </p>
            <p>
                <span style="font-weight: bold;">${assignedRep}: </span> That's great to hear! I'm calling to introduce you to an exciting platform called Pay 321, which we believe can greatly benefit your union. I'd like to take just a few minutes of your time to explain how it can increase member engagement, reduce local expenses, and even boost the chances of your elected executives getting re-elected.
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Pause for acknowledgment]
            </p>
            <p>
                <span style="font-weight: bold;">${assignedRep}: </span> Pay 321 is a cutting-edge platform designed specifically for unions like yours. It provides a centralized and user-friendly hub for all your union's needs. Here's how it can make a significant impact:
            </p>
             <ol style="list-style-type: decimal; padding-left: 1.5em;">
                <li style="margin-bottom: 1em;">
                    <span style="font-weight: bold;">Increase Member Engagement: </span> With Pay 321, your union can create an online community where members can connect, share ideas, and stay informed about union activities. It's like a social network exclusively for your members. This engagement leads to more active and committed members.
                </li>
                <li style="margin-bottom: 1em;">
                    <span style="font-weight: bold;">Decrease Local Expenses: </span> Pay 321 streamlines administrative tasks, from managing dues to organizing events. By automating these processes, you can significantly reduce administrative expenses and ensure that your local union operates more efficiently.
                </li>
                <li style="margin-bottom: 1em;">
                    <span style="font-weight: bold;">Save Time for businesses: </span> Pay 321 offers tools that simplify the work of businesses and administrators. It eliminates manual paperwork, making it easier to track member data, handle grievances, and organize events. This frees up valuable time for your executives to focus on strategic decisions and member engagement.
                </li>
                <li style="margin-bottom: 1em;">
                    <span style="font-weight: bold;">Boost Re-Election Chances: </span> When members see the positive impact of Pay 321 on their union, they're more likely to support the current leadership. Elected executives who can demonstrate improved member engagement and cost savings are more likely to get re-elected.
                </li>
            </ol>

            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Pause for emphasis]
            </p>
            <p>
                <span style="font-weight: bold;">${assignedRep}: </span> We'd love to offer you a personalized demo of the Pay 321 platform so you can see firsthand how it works and how it can benefit your union. We're confident that once you experience its capabilities, you'll be eager to bring it to your union members.
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Wait for a response]
            </p>
            <p>
                <span style="font-weight: bold;">${assignedRep}: </span> Great! We can schedule a demo at your convenience. Just let us know when works best for you, and we'll walk you through the platform's features in detail. Are there any specific questions or concerns you have right now that I can address?
            </p>
            <p>[Wait for a response]   <span style="color: red; margin-top: 2px; font-weight: bold;">[if no then go to below in red]</span></p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Listen and respond to their questions or concerns]
            </p>
            <p>
                <span style="font-weight: bold;">${assignedRep}: </span> I appreciate your time, <span style="textDecoration: underline">${unionData?.contactName || "[business owner]"}</span>. We're excited about the opportunity to support your union's success. I'll follow up with an email containing more information and a link to schedule that demo. Thank you for considering Pay 321, and I look forward to speaking with you again soon.
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [End the call politely]
            </p>
            <hr class="my-4 border-t-2 border-gray-500" />

            <p style="color: red; margin-top: 2px; font-weight: bold;">
                [Pause for emphasis]
            </p>
            <p>
                <span style="font-weight: bold;">${assignedRep}: </span> I'd love to offer you a personalized demo of the Pay 321 platform so you can see exactly how it works and how it can benefit your union. However, I understand that you may have a busy schedule or prefer to explore more at your own pace.
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Pause for response]
            </p>
            <p>
                <span style="font-weight: bold;">${assignedRep}: </span> If you're not ready to schedule a demo right now, may I ask if it's okay to send you a brochure with more detailed information about Pay 321? This way, you can review the benefits and features at your convenience, and when you're ready, we can discuss any questions you may have or schedule a demo if you'd like to explore further.
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Wait for their response]
            </p>
            <p>
                <span style="font-weight: bold;">${assignedRep}: </span> Perfect! I'll make sure to send you our brochure right away. Please keep an eye on your email for it. And if you have any questions or want to discuss anything further, don't hesitate to reach out to us. We're here to support your union's success.
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [End the call politely]
            </p>
            <p>
                Remember to tailor the script to the specific needs and concerns of the potential client, and be ready to provide more information and answer any questions they may have during the call or in subsequent interactions.
            </p>
        </div>
        `,
    voiceMail: `
            <div>

              <p>
                Hello,
                <span style="textDecoration: underline">
                 ${unionData?.contactName || "[business owner]"}
                </span>
                , my name is ${salesRepName}, and I represent Pay 321. I hope this message finds you well. I'm reaching out because we have an exciting platform called Pay 321 that's specifically designed to help unions like yours increase member engagement, reduce expenses, and save valuable time for businesses and administrators.
              </p>
              <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Pause for emphasis]
              </p>
              <p>
                I'd love to connect with you for a brief conversation to share more about Pay 321 and learn about your union's unique needs. There are absolutely no obligations, and it's an opportunity for us to explore how Pay 321 might benefit your union.
              </p>
              <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Provide contact information and invitation to reply]
              </p>
              <p>
                You can reach me at ${phoneNumber}, or you can reply to this voicemail whenever it's convenient for you. I look forward to the possibility of speaking with you and sharing more about how Pay 321 can make a positive impact on your union. Thank you, and I hope to hear from you soon.
              </p>

            </div>
          `,
  };

  return phoneTemplate;
};

export const followUpCall = (unionData) => {
  const salesRepName = findLoggedInUser()
    ? findLoggedInUser().firstname
    : "[Your Name]";
  const phoneNumber = findLoggedInUser()
    ? findLoggedInUser().phonenumber
    : "number";

  const phoneTemplate = {
    subject: "Follow up Phone Call",
    isEmail: false,
    message: `<div>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Begin the call with a warm greeting and introduction]
            </p>
            <p>
                <span style="font-weight: bold;">${salesRepName}: </span> Hello, <span style="textDecoration: underline">${unionData?.contactName || "[business owner]"}</span>, my name is ${salesRepName}, and I'm calling on behalf of Pay 321. How are you today?
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Wait for their response]
            </p>
            <p>
                <span style="font-weight: bold;">${salesRepName}: </span> That's great to hear! I'm calling to introduce you to an exciting platform called Pay 321, which we believe can greatly benefit your union. I'd like to take just a few minutes of your time to explain how it can increase member engagement, reduce local expenses, and even boost the chances of your elected executives getting re-elected.
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Pause for acknowledgment]
            </p>
            <p>
                <span style="font-weight: bold;">${salesRepName}: </span> Pay 321 is a cutting-edge platform designed specifically for unions like yours. It provides a centralized and user-friendly hub for all your union's needs. Here's how it can make a significant impact:
            </p>
            <ol style="list-style-type: decimal; padding-left: 1.5em;">
                <li style="margin-bottom: 1em;">
                    <span style="font-weight: bold;">Increase Member Engagement: </span> With Pay 321, your union can create an online community where members can connect, share ideas, and stay informed about union activities. It's like a social network exclusively for your members. This engagement leads to more active and committed members.
                </li>
                <li style="margin-bottom: 1em;">
                    <span style="font-weight: bold;">Decrease Local Expenses: </span> Pay 321 streamlines administrative tasks, from managing dues to organizing events. By automating these processes, you can significantly reduce administrative expenses and ensure that your local union operates more efficiently.
                </li>
                <li style="margin-bottom: 1em;">
                    <span style="font-weight: bold;">Save Time for businesses: </span> Pay 321 offers tools that simplify the work of businesses and administrators. It eliminates manual paperwork, making it easier to track member data, handle grievances, and organize events. This frees up valuable time for your executives to focus on strategic decisions and member engagement.
                </li>
                <li style="margin-bottom: 1em;">
                    <span style="font-weight: bold;">Boost Re-Election Chances: </span> When members see the positive impact of Pay 321 on their union, they're more likely to support the current leadership. Elected executives who can demonstrate improved member engagement and cost savings are more likely to get re-elected.
                </li>
            </ol>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Pause for emphasis]
            </p>
            <p>
                <span style="font-weight: bold;">${salesRepName}: </span> We'd love to offer you a personalized demo of the Pay 321 platform so you can see firsthand how it works and how it can benefit your union. We're confident that once you experience its capabilities, you'll be eager to bring it to your union members.
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Wait for a response]
            </p>
            <p>
                <span style="font-weight: bold;">${salesRepName}: </span> Great! We can schedule a demo at your convenience. Just let us know when works best for you, and we'll walk you through the platform's features in detail. Are there any specific questions or concerns you have right now that I can address?
            </p>
            <p>[Wait for a response]   <span style="color: red; margin-top: 2px; font-weight: bold;">[if no then go to below in red]</span></p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Listen and respond to their questions or concerns]
            </p>
            <p>
                <span style="font-weight: bold;">${salesRepName}: </span> I appreciate your time, <span style="textDecoration: underline">${unionData?.contactName || "[business owner]"}</span>. We're excited about the opportunity to support your union's success. I'll follow up with an email containing more information and a link to schedule that demo. Thank you for considering Pay 321, and I look forward to speaking with you again soon.
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [End the call politely]
            </p>
            <hr class="my-4 border-t-2 border-gray-500" />

            <p style="color: red; margin-top: 2px; font-weight: bold;">
                [Pause for emphasis]
            </p>
            <p>
                <span style="font-weight: bold;">${salesRepName}: </span> I'd love to offer you a personalized demo of the Pay 321 platform so you can see exactly how it works and how it can benefit your union. However, I understand that you may have a busy schedule or prefer to explore more at your own pace.
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Pause for response]
            </p>
            <p>
                <span style="font-weight: bold;">${salesRepName}: </span> If you're not ready to schedule a demo right now, may I ask if it's okay to send you a brochure with more detailed information about Pay 321? This way, you can review the benefits and features at your convenience, and when you're ready, we can discuss any questions you may have or schedule a demo if you'd like to explore further.
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Wait for their response]
            </p>
            <p>
                <span style="font-weight: bold;">${salesRepName}: </span> Perfect! I'll make sure to send you our brochure right away. Please keep an eye on your email for it. And if you have any questions or want to discuss anything further, don't hesitate to reach out to us. We're here to support your union's success.
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [End the call politely]
            </p>
            <p>
                Remember to tailor the script to the specific needs and concerns of the potential client, and be ready to provide more information and answer any questions they may have during the call or in subsequent interactions.
            </p>
        </div>
        `,
    voiceMail: `
            <div>

              <p>
                Hello,
                <span style="textDecoration: underline">
                 ${unionData?.contactName || "[business owner]"}
                </span>
                , my name is ${salesRepName}, and I represent Pay 321. I hope this message finds you well. I'm reaching out because we have an exciting platform called Pay 321 that's specifically designed to help unions like yours increase member engagement, reduce expenses, and save valuable time for businesses and administrators.
              </p>
              <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Pause for emphasis]
              </p>
              <p>
                I'd love to connect with you for a brief conversation to share more about Pay 321 and learn about your union's unique needs. There are absolutely no obligations, and it's an opportunity for us to explore how Pay 321 might benefit your union.
              </p>
              <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
                [Provide contact information and invitation to reply]
              </p>
              <p>
                You can reach me at ${phoneNumber}, or you can reply to this voicemail whenever it's convenient for you. I look forward to the possibility of speaking with you and sharing more about how Pay 321 can make a positive impact on your union. Thank you, and I hope to hear from you soon.
              </p>

            </div>
          `,
  };

  return phoneTemplate;
};
export const wakeUpCall = (unionData) => {
  const salesRepName = findLoggedInUser()
    ? findLoggedInUser().firstname
    : "[Your Name]";
  const phoneNumber = findLoggedInUser()
    ? findLoggedInUser().phonenumber
    : "number";

  const phoneTemplate = {
    subject: "Wake up call after wait letters",
    isEmail: false,
    message: `<div>
            <p style="margin-bottom: 2px;">
        <span style="font-weight: bold;">${salesRepName}: </span> Good [Morning/Afternoon], this is ${salesRepName} from Union Technologies. How have you been?
        </p>
        <p style="margin-bottom: 2px;">
    <span style="font-weight: bold; color: #555555;">${unionData?.contactName || "[business owner]"}: </span> Hello, ${salesRepName}. I've been well, thank you. What can I do for you today?
    </p>
    <p style="margin-bottom: 2px;">
    <span style="font-weight: bold;">${salesRepName}: </span> I'm glad to hear that. I wanted to touch base with you regarding the letters and emails we sent concerning the Pay 321 platform. Have you had a chance to review them?
    </p>
    <p style="margin-bottom: 2px;">
    <span style="font-weight: bold; color: #555555;">${unionData?.contactName || "[business owner]"}: </span>I did receive them, but I must admit I haven't had the time to go through everything yet.
    </p>
    <p style="margin-bottom: 2px;">
    <span style="font-weight: bold;">${salesRepName}: </span> That's completely understandable. We appreciate your busy schedule. The reason I'm reaching out is to emphasize our enthusiasm about how Pay 321 can benefit your union. It's designed to increase member engagement, reduce expenses, save valuable time for your executive team, and even enhance the chances of successful re-elections by fostering stronger connections with your members.
    </p>
    <p style="margin-bottom: 2px;">
    <span style="font-weight: bold; color: #555555;">${unionData?.contactName || "[business owner]"}: </span>Those are important considerations for us, and I appreciate the reminder.
    </p>
    <p style="margin-bottom: 2px;">   
    <span style="font-weight: bold;">${salesRepName}: </span> Of course, we understand that evaluating a decision like this takes time. To help you get a better sense of how Pay 321 can work for your union, we'd like to suggest scheduling a call where we can discuss it in more detail.
    </p> 
    <p style="margin-bottom: 2px;">
    <span style="font-weight: bold; color: #555555;">${unionData?.contactName || "[business owner]"}: </span>That might be a good idea. Can you provide more information about what the call would entail?
    </p>
    <p style="margin-bottom: 2px;">
    <span style="font-weight: bold;">${salesRepName}: </span> Certainly. During the call, we'll provide an overview of the platform's features, discuss how it aligns with your union's specific goals, and address any questions or concerns you may have. It's an opportunity for us to provide you with deeper insights into the potential benefits of Pay 321 for your organization.
    </p>
    <p style="margin-bottom: 2px;">
    <span style="font-weight: bold; color: #555555;">${unionData?.contactName || "[business owner]"}: </span>I appreciate that. Let's go ahead and schedule that call.
    </p>
    <p style="margin-bottom: 2px;">
    <span style="font-weight: bold;">${salesRepName}: </span> Fantastic! How about we aim for [suggest date and time]? Does that work for you, or would you prefer a different time slot?
    </p>
    <p style="margin-bottom: 2px;">
    <span style="font-weight: bold; color: #555555;">${unionData?.contactName || "[business owner]"}: </span>[Confirm or suggest an alternate date and time if needed]
    <p style="margin-bottom: 2px;"></p>
    </p>
    <span style="font-weight: bold;">${salesRepName}: </span> Perfect, I've scheduled our call for [agreed date and time]. I'll send you a calendar invite shortly. In the meantime, if you have any specific questions or topics you'd like to cover during our discussion, please feel free to share them with me.
    <p style="margin-bottom: 2px;">
    <span style="font-weight: bold; color: #555555;">${unionData?.contactName || "[business owner]"}: </span>Thank you, ${salesRepName}. I appreciate your patience, and I look forward to our call.
    </p>
    <p style="margin-bottom: 2px;">
    <span style="font-weight: bold;">${salesRepName}: </span> You're very welcome. Thank you for considering Pay 321, and I'm excited to explore how it can benefit your union. If you have any more immediate questions or concerns, don't hesitate to reach out before our scheduled call.
    </p>
    <p style="margin-bottom: 2px;">
    <span style="font-weight: bold; color: #555555;">${unionData?.contactName || "[business owner]"}: </span>I will do that. Have a great day, ${salesRepName}.
    </p>
    <p style="margin-bottom: 2px;">
    <span style="font-weight: bold;">${salesRepName}: </span> You too, <span style="textDecoration: underline">${unionData?.contactName || "[business owner]"}</span>. Talk to you soon.
    </p>
    Remember to personalize the script to your specific situation and maintain a friendly and professional tone throughout the conversation. The goal is to gently remind and engage the union executive while respecting their time constraints.
    </div>`,
    voiceMail: `
    <div>
        <p style="margin-bottom: 2px;">
        Hello, this is ${salesRepName} from Union Technologies. I hope this message finds you well.
        </p>
        <p style="margin-bottom: 2px;">I wanted to reach out regarding the letters and emails we've sent regarding the Pay 321 platform. We understand that you have a busy schedule, and reviewing these materials may take some time.
    </p>
    <p style="margin-bottom: 2px;">We're genuinely excited about how Pay 321 can benefit your union. It's designed to increase member engagement, reduce expenses, save valuable time for your executive team, and even enhance the chances of successful re-elections by fostering stronger connections with your members.
    </p>
    <p style="margin-bottom: 2px;">To assist you in understanding the platform better, we'd like to suggest scheduling a call where we can discuss it in more detail. During this call, we can provide you with a deeper insight into how Pay 321 can work specifically for your union.
    </p>
    <p style="margin-bottom: 2px;">If you're interested in scheduling this call or if you have any immediate questions, please feel free to call us back at ${phoneNumber}. We're here to support you and provide you with any information you may need.
    </p>
    <p style="margin-bottom: 2px;">Thank you for considering Pay 321, and we look forward to the opportunity to work together effectively using the Pay 321 platform.</p>
    
    <p style="margin-bottom: 2px;">Have a great day.</p>
    

    *** Make sure to speak clearly and leave your contact number at a moderate pace to ensure the recipient can easily understand and call back if interested.***</div>`,
  };
  return phoneTemplate;
};
export const confirmDemo = ({
  unionData,
  startTime,
  endTime,
  appointmentDate,
}) => {
  const salesRepName = findLoggedInUser()
    ? findLoggedInUser().firstname
    : "[Your Name]";
  const phoneNumber = findLoggedInUser()
    ? findLoggedInUser().phonenumber
    : "number";
  const email = findLoggedInUser() ? findLoggedInUser().email : "email";

  const timeString =
    unionData && unionData.timeZone
      ? `${formatTimeToLocalTime(startTime, endTime, unionData.timeZone).formattedStartTime} - ${formatTimeToLocalTime(startTime, endTime, unionData.timeZone).formattedEndTime} (${unionData.timeZone})`
      : `${startTime ? startTime : "[Start Time]"} - ${endTime ? endTime : "[End Time]"} (Canada/Eastern)`;
  const phoneTemplate = {
    subject: "Confirm Demo",
    isEmail: false,
    message: `
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">
              [Begin the call with a warm greeting]
            </p>
            <p>
              <span style="font-weight: bold;">${salesRepName}: </span> Hello,
              ${unionData?.contactName ? unionData?.contactName : `[Recipient's Name]`}. I hope you're doing well. my
              name is ${salesRepName}, and I'm calling from Union Technologies. I trust you received our confirmation
              email regarding our upcoming presentation appointment scheduled for
              ${appointmentDate ? formatSpelledDate(appointmentDate) : `[appointment Date]`} at
              ${startTime ? timeString : `[start time]`}. Is that correct?
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">[Wait for their response]</p>
            <p style="font-weight: bold;">${salesRepName}: </p>
            <p>
              Fantastic! I wanted to touch base with you to ensure everything is set for our meeting. We're excited
              to have the opportunity to share how Pay 321 can benefit your union, and we want to ensure that the
              presentation goes smoothly.
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">[Pause for acknowledgment]</p>
            <p style="font-weight: bold;">${salesRepName}: </p>
            <p>
              During our presentation, we'll provide a detailed demonstration of Pay 321's capabilities, including
              how it can help increase member engagement, reduce expenses, and save valuable time for businesses
              and administrators. We'll be ready to answer any questions you may have as well.
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">[Pause for emphasis]</p>
            <p style="font-weight: bold;">${salesRepName}: </p>
            <p>
              If there's anything specific you'd like to discuss or if you have any questions in advance, please feel
              free to let us know. Your insights and requirements are essential to us, and we want to make sure we
              address them during our presentation.
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">[Pause for any questions or concerns]</p>
            <p style="font-weight: bold;">${salesRepName}: </p>
            <p>
              Great! If there are no further questions, we'll be looking forward to our meeting on
              ${appointmentDate ? formatSpelledDate(appointmentDate) : `[appointment Date]`} at
              ${startTime ? timeString : `[start time]`}. The meeting link or venue address can be found in the
              confirmation email we sent earlier, but if you need it again, please don't hesitate to ask.
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">[Wait for acknowledgment]</p>
            <p style="font-weight: bold;">${salesRepName}: </p>
            <p>
              Thank you for your time,
              ${unionData?.contactName ? unionData?.contactName : `[Recipient's Name]`}. We're excited about the
              opportunity to connect and explore how Pay 321 can contribute to your union's success. If anything
              comes up or if you need to reschedule for any reason, please don't hesitate to reach out to us at
              ${email} or ${phoneNumber}.
            </p>
            <p style="margin-top: 2px; margin-bottom: 2px; color: navy;">[End the call politely]</p>
          
        `,
  };

  return phoneTemplate;
};

export const interimCall = (unionData) => {
  const salesRepName = findLoggedInUser()
    ? findLoggedInUser().firstname
    : "[Your Name]";
  const phoneNumber = findLoggedInUser()
    ? findLoggedInUser().phonenumber
    : "number";

  const phoneTemplate = {
    subject: "Phone script for follow up to wait letters",
    isEmail: false,
    message: `
                <p style="margin-top: 2px; margin-bottom: 2px;">
                    <span style="font-weight:bold">${salesRepName}:</span> Good [Morning/Afternoon], this is ${salesRepName} from Union Technologies. How are you today?
                </p>
                <p style="color:#555555;">
                    <span style="font-weight:bold">${unionData?.contactName || "[Union Executive's Name]"}:</span> Hello, ${salesRepName}. I'm doing well, thank you. How can I help you today?
                </p>
                <p style="margin-top: 2px; margin-bottom: 2px;">
                    <span style="font-weight:bold">${salesRepName}:</span> I'm glad to hear that. I wanted to follow up on the letters and emails we sent regarding the Pay 321 platform. I hope you received them.
                </p>
                <p>
                    <span style="font-weight:bold">${unionData?.contactName || "[Union Executive's Name]"}:</span> Yes, I did receive them. Thank you for reaching out.
                </p>
                <p style="margin-top: 2px; margin-bottom: 2px;">
                    <span style="font-weight:bold">${salesRepName}:</span> You're welcome. We're quite enthusiastic about how Pay 321 can benefit your union, particularly in terms of increasing member engagement, reducing expenses, and saving valuable time for your executive team. It's also designed to enhance the chances of successful re-elections by fostering stronger connections with your members.
                </p>
                <p>
                    <span style="font-weight:bold">${unionData?.contactName || "[Union Executive's Name]"}:</span> Yes, those are important considerations for us. I just haven't had the time to go through all the information thoroughly.
                </p>
                <p style="margin-top: 2px; margin-bottom: 2px;">
                    <span style="font-weight:bold">${salesRepName}:</span> Completely understandable. We respect your timeline and know that evaluating such a decision takes time. To assist you in understanding the platform better, we'd like to schedule a call where we can discuss how Pay 321 can be tailored to serve your union's specific needs effectively.
                </p>
                <p>
                    <span style="font-weight:bold">${unionData?.contactName || "[Union Executive's Name]"}:</span> That sounds reasonable. Can you provide more details about what the call would entail?
                </p>
                <p style="margin-top: 2px; margin-bottom: 2px;">
                    <span style="font-weight:bold">${salesRepName}:</span> Certainly. During the call, we'll walk you through the platform's features, discuss how it aligns with your union's goals, and answer any questions or concerns you may have. It's an opportunity to gain a deeper understanding of the platform's potential impact on your organization.
                </p>
                <p>
                    <span style="font-weight:bold">${unionData?.contactName || "[Union Executive's Name]"}:</span> I appreciate that. Let's go ahead and schedule that call.
                </p>
                <p style="margin-top: 2px; margin-bottom: 2px;">
                    <span style="font-weight:bold">${salesRepName}:</span> Great! How about we aim for [suggest date and time]? Does that work for you, or would you prefer a different time slot?
                </p>
                <p>
                    <span style="font-weight:bold">${unionData?.contactName || "[Union Executive's Name]"}:</span> [Confirm or suggest an alternate date and time if needed]
                </p>
                <p style="margin-top: 2px; margin-bottom: 2px;">
                    <span style="font-weight:bold">${salesRepName}:</span> Perfect, I've scheduled our call for [agreed date and time]. I'll send you a calendar invite shortly. In the meantime, if you have any specific questions or topics you'd like to cover during our discussion, please feel free to share them with me.
                </p>
                <p>
                    <span style="font-weight:bold">${unionData?.contactName || "[Union Executive's Name]"}:</span> Thank you, ${salesRepName}. I appreciate your patience, and I look forward to our call.
                </p>
                <p style="margin-top: 2px; margin-bottom: 2px;">
                    <span style="font-weight:bold">${salesRepName}:</span> You're very welcome. Thank you for considering Pay 321, and I'm excited to explore how it can benefit your union. If you have any more immediate questions or concerns, don't hesitate to reach out before our scheduled call.
                </p>
                <p>
                    <span style="font-weight:bold">${unionData?.contactName || "[Union Executive's Name]"}:</span> I will do that. Have a great day, ${salesRepName}.
                </p>
                <p style="margin-top: 2px; margin-bottom: 2px;">
                    <span style="font-weight:bold">${salesRepName}:</span> You too,${unionData?.contactName || "[Union Executive's Name]"}. Talk to you soon.
                </p>
            `,
    voiceMail: `
                <p style="margin-top: 2px; margin-bottom: 2px;">
                    Hello, ${unionData?.contactName || "[Union Executive's Name]"}. my name is ${salesRepName}, and I'm calling from Union Technologies. I hope this message finds you well. I've sent you a couple of emails about a platform called Pay 321, and I wanted to extend an invitation for a short conversation.
                </p>
                <p style="margin-top: 2px; margin-bottom: 2px;">
                    Pay 321 is specifically designed to help unions like yours increase member engagement, reduce expenses, and save valuable time for businesses and administrators. There's absolutely no obligation, and it's a chance for us to connect, share more about Pay 321, and learn about your union's unique needs.
                </p>
                <p style="margin-top: 2px; margin-bottom: 2px;">
                    If you're interested, please feel free to call me back at ${phoneNumber}, or you can reply to one of the emails I've sent. I look forward to the opportunity to chat with you and explore how Pay 321 can benefit your union. Thank you, and I hope to speak with you soon.
                </p>
            `,
  };

  return phoneTemplate;
};
