import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import "chart.js/auto";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import users from "../../data/users.json";
import Analytics from "./Analytics";
//import AnalyticsTable from "./AnalyticsTable";
import AnalyticsTable2 from "./AnalyticsTable2";

export default function AnalyticsContent2({ selectedSalesRep }) {
  const [chartData, setChartData] = useState({});
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {
      try {
        // Make Axios request to the provided API endpoint
        const response = await axios.get(
          "https://apijonluca.netlify.app/.netlify/functions/api/unions",
        );

        // Process the data based on selectedSalesRep
        const userChartData = {};

        const clientTypeColors = {
          Suspect: "#A1EACD",
          Prospect: "#FFF1B0",
          Activation: "#FFD894",
          Proposal: "#FF7F50",
          Client: "#50C878",
          // Add more client types and colors if needed
        };

        // If selectedSalesRep is 'all', process data for all users
        if (selectedSalesRep === "all") {
          for (const user of users) {
            const countByClientType = response.data.reduce((acc, union) => {
              const { assignedRep, clientType } = union;

              // Check if clientType is not null and assignedRep matches the current user
              if (
                clientType != null &&
                clientType !== "Client" &&
                assignedRep === user.firstname
              ) {
                acc[clientType] = (acc[clientType] || 0) + 1;
              }

              return acc;
            }, {});

            // If the user has any items with clientType, prepare data for Pie chart
            if (Object.keys(countByClientType).length > 0) {
              userChartData[user.firstname] = {
                labels: Object.keys(countByClientType),
                datasets: [
                  {
                    data: Object.values(countByClientType),
                    backgroundColor: Object.keys(countByClientType).map(
                      (type) => clientTypeColors[type],
                    ),
                    hoverBackgroundColor: Object.keys(countByClientType).map(
                      (type) => clientTypeColors[type],
                    ),
                  },
                ],
              };
            }
          }
        } else {
          // If selectedSalesRep is a specific user, process data for that user only
          const selectedUser = users.find(
            (user) => user.firstname === selectedSalesRep,
          );

          if (selectedUser) {
            const countByClientType = response.data.reduce((acc, union) => {
              const { assignedRep, clientType } = union;

              // Check if clientType is not null and assignedRep matches the selected user
              if (
                clientType != null &&
                clientType !== "Client" &&
                assignedRep === selectedUser.firstname
              ) {
                acc[clientType] = (acc[clientType] || 0) + 1;
              }

              return acc;
            }, {});

            // If the selected user has any items with clientType, prepare data for Pie chart
            if (Object.keys(countByClientType).length > 0) {
              userChartData[selectedUser.firstname] = {
                labels: Object.keys(countByClientType),
                datasets: [
                  {
                    data: Object.values(countByClientType),
                    backgroundColor: Object.keys(countByClientType).map(
                      (type) => clientTypeColors[type],
                    ),
                    hoverBackgroundColor: Object.keys(countByClientType).map(
                      (type) => clientTypeColors[type],
                    ),
                  },
                ],
              };
            }
          }
        }

        // Update state with the prepared data
        setChartData(userChartData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Fetch data when the component mounts or when selectedSalesRep changes
    fetchData();
  }, [selectedSalesRep]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div>
      <div
        className="flex items-center cursor-pointer mb-2"
        onClick={toggleCollapse}
      >
        {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
        <span className="ml-2">
          {isCollapsed ? "Show Analytics" : "Hide Analytics"}
        </span>
      </div>

      {!isCollapsed && (
        <div className="flex gap-4 overflow-x-auto">
          {Object.keys(chartData).map((username, index) => (
            <div
              key={index}
              className="p-2 rounded-md bg-zinc-100 shadow-md flex"
            >
              {/* col 1 */}
              <div>
                <div>
                  <span className="text-lg font-bold">{username} </span>
                </div>
                <Analytics selectedSalesRep={username.toLowerCase()} />
                <div style={{ marginTop: "10px",width:"250px" }}>
                  <Pie data={chartData[username]} />
                </div>
              </div>
              {/* col 2 */}
              <div>
                <AnalyticsTable2 selectedSalesRep={username.toLowerCase()} />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
