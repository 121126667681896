import {
  findLoggedInUser,
  loggedInUser,
  returnUserFullName,
} from "../localStorage";
import { calculateTimeDifference, formatTimeToLocalTime } from "../formatTime";

//const title = findLoggedInUser()?findLoggedInUser().title:'title'
const fullName = returnUserFullName();
const phoneNumber = findLoggedInUser()
  ? findLoggedInUser().phonenumber
  : "number";
const email = findLoggedInUser() ? findLoggedInUser().email : "email";

const repName = (unionData) => {
  if (unionData.primaryContact && unionData.primaryContact === 1) {
    return unionData.contactName || "Union Representative";
  } else {
    return unionData.alternateContactName || "Union Representative";
  }
};

export const noLetterNoBudget = (unionData) => {
  const emailTemplate = {
    subject: "Supporting Each Other in the Labour Movement",
    isEmail: true,
    message: `Dear ${repName(unionData)},
        
        I trust this email finds you well. I wanted to reach out to discuss the recent conversation regarding the budget for our services and application. I understand that budget constraints can be challenging, especially in the context of the valuable work carried out by our business community.

        While I acknowledge that financial considerations are crucial, I want to emphasize that our commitment to supporting your business goes beyond monetary constraints. We understand the unique challenges faced by labor businesses and the critical role they play in the broader labor movement.

        The labor business community is, indeed, a close-knit and interconnected network. We firmly believe that supporting one another is not solely about the financial aspect but rather about fostering a collaborative environment that strengthens the collective voice of workers. Our mission is to contribute to the well-being and success of businesses, ensuring their sustainability in the long run.

        In light of the current budget limitations, we want to assure you that our dedication to assisting your business remains unwavering. We are more than willing to explore alternative solutions or find ways to tailor our services to better align with your financial capacity. Our goal is to ensure that your business can continue its essential work without compromising the quality of the services and support it receives.

        It's essential for all of us in the labor movement to unite and support each other. The challenges we face are shared, and by working together, we can overcome them and contribute to the overall strength and resilience of the labor movement.

        As a suggestion, it might be beneficial to 𝗽𝗿𝗶𝗻𝘁 𝗮𝗻𝗱 𝘀𝗮𝘃𝗲 𝘁𝗵𝗶𝘀 𝗲𝗺𝗮𝗶𝗹 in your business's files. Having this on hand could serve as a reference point for future discussions or when the time is right to reconsider our services. We are always available for further discussions, and you can easily reach out when needed.

        If you have any specific concerns or would like to discuss potential adjustments to our services, please feel free to reach out. We are here to collaborate with you and find solutions that meet your business's needs.

        Thank you for your understanding, and we look forward to continuing our partnership in support of the labor movement.`,
  };
  return emailTemplate;
};

export const noLetterNotInterested = (unionData) => {
  const emailTemplate = {
    subject: "Thank You for Considering Our Services",
    isEmail: true,
    message: `Dear ${repName(unionData)},
        
        I trust this message finds you well. I wanted to express our sincere gratitude for the time and consideration you dedicated to exploring the possibility of our services for your business. It has been a pleasure learning about your organization and its goals.

        After careful consideration, we understand that our services may not align with your current needs or priorities. We respect your decision and appreciate the transparency in communicating your preferences. Your honesty allows us to focus our efforts where they are most needed and ensures that our interactions are respectful of your time and resources.

        Please know that even though our services may not be the right fit at this time, we remain committed to supporting the broader labor movement. Should your business's needs evolve in the future, we would be more than happy to revisit our discussions and explore how our services might better align with your requirements.

        In the meantime, we genuinely wish your business continued success in all its endeavors. If there are ever c͟h͟a͟n͟g͟e͟s͟ in your circumstances or if you have questions about our services in the future, please do not hesitate to reach out. We are here to assist in any way we can.

        As a suggestion, it might be beneficial to 𝘀𝗮𝘃𝗲 𝗮𝗻𝗱 𝗽𝗿𝗶𝗻𝘁 𝘁𝗵𝗶𝘀 𝗲𝗺𝗮𝗶𝗹 for future reference. Keeping a copy on hand could make it easier to reconnect should your business's situation change, or if you wish to explore our services at a later date.

        Thank you once again for considering our services, and we wish you and your business all the best in your important work.`,
  };
  return emailTemplate;
};

export const noLetterNotNeeded = (unionData) => {
  const emailTemplate = {
    subject: "Appreciation for Your Consideration of Our Services",
    isEmail: true,
    message: `Dear ${repName(unionData)},
        
        I hope this message finds you well. I wanted to extend my sincere appreciation for the time and consideration you invested in exploring the potential of our services for your business. Learning about your organization and its objectives has been enlightening.

        After careful consideration, it has come to our understanding that, at present, our services may not align with your current needs or priorities. We respect your decision and value the openness in communicating your preferences. This transparency allows us to focus our efforts where they are most needed and ensures that our interactions are respectful of your time and resources.

        Please be assured that even though our services may not be immediately applicable, our commitment to supporting the broader labor movement remains unwavering. Should your business's needs evolve in the future, we would welcome the opportunity to revisit our discussions and explore how our services might better align with your requirements.

        In the meantime, we sincerely wish your business continued success in all its endeavors. If there are ever 𝘤𝘩𝘢𝘯𝘨𝘦𝘴 𝘪𝘯 𝘺𝘰𝘶𝘳 𝘤𝘪𝘳𝘤𝘶𝘮𝘴𝘵𝘢𝘯𝘤𝘦𝘴 or if you have questions about our services down the road, please do not hesitate to reach out. We are here to assist in any way we can.

        As a suggestion, it might be helpful to 𝘀𝗮𝘃𝗲 𝗮𝗻𝗱 𝗽𝗿𝗶𝗻𝘁 𝘁𝗵𝗶𝘀 𝗲𝗺𝗮𝗶𝗹 for future reference. Having a copy on hand could make it more convenient to reconnect should your business's circumstances change, or if you decide to explore our services at a later date.

        Once again, thank you for considering our services, and we wish you and your business ongoing success in your vital work.`,
  };
  return emailTemplate;
};

export const noLetterReferral = (unionData) => {
  const emailTemplate = {
    subject: "Seeking Your Valuable Network Insights",
    isEmail: true,
    message: `Dear ${repName(unionData)},

        I trust this email finds you well. I wanted to express our gratitude for your time and consideration in evaluating our services for your business. While we understand that our offerings may not be an immediate fit for your local, we were wondering if you could provide some insights or recommendations.
        
        Given your experience and network within the labor community, you might be aware of other locals or businesses in the area that could benefit from our services. Our offerings are designed to help businesses better engage their membership, reduce expenses, and save valuable time for the executive team.
        
        If you know of any local businesses that might find value in our services, we would greatly appreciate it if you could either forward this email to them or provide us with a contact name and details. Your assistance in connecting us with potential partners would be immensely valuable, and we assure you that we will handle any referrals with the utmost care and respect.

        As a suggestion, it might be helpful to save and print this email for future reference. Having a copy on hand could make it more convenient to revisit and share this message with your network when the opportunity arises.

        We understand the importance of collaboration within the labor movement, and your support in this matter could go a long way in strengthening the collective efforts of our businesses.
  
        Thank you once again for your time and consideration. We look forward to any insights you may have, and please feel free to reach out if you have any questions or if there's anything else we can assist you with.
        `,
  };
  return emailTemplate;
};

export const waitReason1 = (unionData) => {
  const fullName = returnUserFullName();

  const emailTemplate = {
    subject:
      "Introducing Pay 321 - Your Future Partner in Union Focused Solutions",
    isEmail: true,
    message: `Dear ${repName(unionData)},
        
        I trust this message finds you well. My name is ${fullName}, and I am reaching out on behalf of Pay 321, a dedicated Union-focused platform. As a representative of our Union local-focused company operating exclusively in Canada and the USA, we understand the unique challenges faced by labor businesses in today's dynamic landscape.

        While we recognize that it might not be the opportune moment for your organization to consider a switch in providers, we believe in establishing connections and fostering relationships within the labor business community. Our commitment to supporting businesses like yours is unwavering, and we would like to introduce you to Pay 321 through our attached brochure.

        [Attach Brochure]

        We kindly request that you print this email and our brochure for your records. In the event that circumstances change, and you find yourself exploring new solutions, we would appreciate it if you consider Pay 321 as your trusted partner. Our platform is designed to address the specific needs of businesses, providing a comprehensive and tailored approach to enhance your operations.

        Pay 321 is not just a platform; it's a commitment to the solidarity and strength that businesses bring to their members. We are here to assist you in any way possible, whether it's answering questions, providing additional information, or offering a personalized demonstration of our platform.

        The labor business world is indeed a tight-knit community, and we believe that our paths will cross in the future. We look forward to the opportunity to collaborate and contribute to the success of your organization when the time is right for you.

        Feel free to reach out if you have any questions or if there's anything specific you would like to discuss. We appreciate your consideration and look forward to the possibility of working together.      
        `,
  };
  return emailTemplate;
};

export const waitReason2 = (unionData) => {
  const fullName = returnUserFullName();

  const emailTemplate = {
    subject:
      "Introducing Pay 321 - Your Future Partner in Union Focused Solutions",
    isEmail: true,
    message: `Dear ${repName(unionData)},
        
        I trust this message finds you well. My name is ${fullName}, and I am reaching out on behalf of Pay 321, a dedicated Union-focused platform. As a representative of our Union local-focused company operating exclusively in Canada and the USA, we understand the unique challenges faced by labor businesses in today's dynamic landscape.

        While we recognize that it might not be the opportune moment for your organization to consider a switch in providers, we believe in establishing connections and fostering relationships within the labor business community. Our commitment to supporting businesses like yours is unwavering, and we would like to introduce you to Pay 321 through our attached brochure.

        [Attach Brochure]

        We kindly request that you print this email and our brochure for your records. In the event that circumstances change, and you find yourself exploring new solutions, we would appreciate it if you consider Pay 321 as your trusted partner. Our platform is designed to address the specific needs of businesses, providing a comprehensive and tailored approach to enhance your operations.

        Pay 321 is not just a platform; it's a commitment to the solidarity and strength that businesses bring to their members. We are here to assist you in any way possible, whether it's answering questions, providing additional information, or offering a personalized demonstration of our platform.

        The labor business world is indeed a tight-knit community, and we believe that our paths will cross in the future. We look forward to the opportunity to collaborate and contribute to the success of your organization when the time is right for you.

        Feel free to reach out if you have any questions or if there's anything specific you would like to discuss. We appreciate your consideration and look forward to the possibility of working together.      
        `,
  };
  return emailTemplate;
};

export const waitReason3 = (unionData) => {
  const fullName = returnUserFullName();

  const emailTemplate = {
    subject:
      "Introducing Pay 321 - Your Future Partner in Union Focused Solutions",
    isEmail: true,
    message: `Dear ${repName(unionData)},
        
        I trust this message finds you well. My name is ${fullName}, and I am reaching out on behalf of Pay 321, a dedicated Union-focused platform. As a representative of our Union local-focused company operating exclusively in Canada and the USA, we understand the unique challenges faced by labor businesses in today's dynamic landscape.

        While we recognize that it might not be the opportune moment for your organization to consider a switch in providers, we believe in establishing connections and fostering relationships within the labor business community. Our commitment to supporting businesses like yours is unwavering, and we would like to introduce you to Pay 321 through our attached brochure.

        [Attach Brochure]

        We kindly request that you print this email and our brochure for your records. In the event that circumstances change, and you find yourself exploring new solutions, we would appreciate it if you consider Pay 321 as your trusted partner. Our platform is designed to address the specific needs of businesses, providing a comprehensive and tailored approach to enhance your operations.

        Pay 321 is not just a platform; it's a commitment to the solidarity and strength that businesses bring to their members. We are here to assist you in any way possible, whether it's answering questions, providing additional information, or offering a personalized demonstration of our platform.

        The labor business world is indeed a tight-knit community, and we believe that our paths will cross in the future. We look forward to the opportunity to collaborate and contribute to the success of your organization when the time is right for you.

        Feel free to reach out if you have any questions or if there's anything specific you would like to discuss. We appreciate your consideration and look forward to the possibility of working together.      
        `,
  };
  return emailTemplate;
};
export const waitLetter1 = (unionData) => {
  const emailTemplate = {
      subject: 'Follow-up on Your Request for Information - VoIP Internet Support',
      isEmail: true,
      message: `
          Dear ${unionData.contactName || 'Business Owner'},

          I hope this message finds you well. We're reaching out to follow up on your recent request for more information about our VoIP internet support services. We understand that you may be busy, and we want to ensure that we've addressed all your questions and concerns.

          We have attempted to reach you by phone, and we're also available for a brief VoIP call to provide the information you requested. Your satisfaction is our priority, and we are committed to assisting you in making an informed decision.

          If there is any specific information you require or if you have any questions, please let us know. We are more than willing to accommodate your schedule for a brief VoIP call or provide detailed documentation to assist in your evaluation.

          Your time and feedback are valuable to us, and we want to ensure that we meet your needs effectively. Please do not hesitate to reach out to us at your earliest convenience.

          Thank you for considering our VoIP internet support services, and we look forward to the opportunity to assist you further.
      `,
  };
  return emailTemplate;
};

export const waitLetter2 = (unionData) => {
  const emailTemplate = {
      subject: 'Second Follow-up: Information on VoIP Internet Support Services',
      isEmail: true,
      message: `
          Dear ${unionData.contactName || 'Business Owner'},

          I hope this message finds you well. We're sending a gentle reminder regarding your request for information about our VoIP internet support services. We understand that your time is valuable, and we want to ensure that you have the details you need to make an informed decision.

          We have made multiple attempts to contact you via phone, and we're also available for a brief VoIP call to address any questions or concerns you may have. We remain eager to assist you and ensure you have all the necessary information at your disposal.

          If you require specific information, have questions, or would like to schedule a brief VoIP call, please don't hesitate to reach out. We are here to accommodate your needs and ensure you have all the information you need.

          Your feedback is important to us, and we value the opportunity to serve your organization. Please consider getting in touch with us so that we can continue to assist you effectively.

          Thank you for your time and consideration.
      `,
  };
  return emailTemplate;
};

export const waitLetter3 = (unionData) => {
  const emailTemplate = {
      subject: 'Final Follow-up: Information on VoIP Internet Support Services',
      isEmail: true,
      message: `
          Dear ${unionData.contactName || 'Business Owner'},

          I hope this message finds you well. This is our final follow-up regarding your request for information about our VoIP internet support services. We've made several attempts to connect with you via phone, and we're also available for a brief VoIP call.

          We remain committed to assisting you in your evaluation of our VoIP internet support services. If there are any specific details, questions, or concerns you'd like to discuss, please let us know. Your satisfaction is of the utmost importance to us, and we want to ensure that you have all the resources you need.

          If there are any developments or if you wish to revisit the discussion at a later time, we'll be here to assist you. We appreciate your interest in our VoIP internet support services and the opportunity to serve your organization.

          Thank you for considering our services, and we wish you all the best in your endeavors.
      `,
  };
  return emailTemplate;
};

export const waitLetter4 = (unionData) => {
  const emailTemplate = {
    subject: "Enhancing Union Engagement with Pay 321",
    isEmail: true,
    message: `Dear ${repName(unionData)},

    I hope this email finds you well. We're circling back once again regarding your inquiry about the Pay 321 platform. Pay 321 is an innovative platform tailored to the unique needs of businesses, designed to increase member engagement, reduce operational expenses, and save valuable time for staff and managers.
    
    Despite our previous attempts to reach out, we haven't had the pleasure of connecting with you. We're committed to providing you with the information you require to make an informed decision about Pay 321.
    
    If there's any particular aspect of our platform you'd like more information on, or if you have any concerns we can address, please don't hesitate to let us know. We're more than willing to accommodate your preferred method of communication, whether it's via email, phone, or even a virtual meeting.
    
    Your satisfaction is paramount to us, and we're dedicated to assisting you every step of the way. We appreciate your consideration and eagerly await the opportunity to support your organization's goals.
    
    Thank you for your time and attention.
    `,
  };
  return emailTemplate;
};

export const waitLetter5 = (unionData) => {
  const emailTemplate = {
    subject: "Your Input Matters - Let Us Help You Further",
    isEmail: true,
    message: `Dear ${repName(unionData)},
    
    I hope this email finds you doing well. Despite our efforts to connect with you, we haven't received a response. We're committed to assisting you in any way we can. Pay 321 is an exciting platform designed to revolutionize how businesses operate, offering streamlined communication, enhanced member engagement, and optimized operational efficiency.

    Your feedback is invaluable to us, and we're eager to hear from you. Please don't hesitate to reach out if there's anything we can do to facilitate your decision-making process.
    
    Thank you for considering Pay 321. We appreciate the opportunity to be of service to you and your organization.
    `,
  };
  return emailTemplate;
};

export const waitLetter6 = (unionData) => {
  const emailTemplate = {
    subject: "Continuing Support for Your Evaluation",
    isEmail: true,
    message: `Dear ${repName(unionData)},

    I trust this email finds you well. Despite our previous attempts to connect with you, we haven't had the pleasure of speaking with you directly. We understand that timing is crucial, and we're here to assist you whenever you're ready.
    
    Pay 321 is a great platform that will benefit your business by streamlining communication, increasing member engagement, and optimizing operational efficiency.
    
    Whether you require further information, have specific questions, or would like to discuss your organization's unique requirements, we're here to help. Your satisfaction is our priority, and we're committed to providing you with the support you need to make an informed decision.
    
    Please feel free to reach out to us at your earliest convenience. We're eager to continue supporting your evaluation and to assist you in any way we can.
    
    Thank you for considering our platform. We look forward to the opportunity to work with you.
    `,
  };
  return emailTemplate;
};

export const waitLetter7 = (unionData) => {
  const emailTemplate = {
    subject: "Elevate Your Union's Potential with Pay 321",
    isEmail: true,
    message: `Dear ${repName(unionData)},

    I hope this message finds you well. Pay 321 presents a transformative opportunity for your business, offering unparalleled tools to streamline operations, boost member engagement, and enhance communication.
    
    Despite our previous attempts to connect, we haven't had the opportunity to discuss how Pay 321 can benefit your company specifically. We understand the importance of finding the right solution, and we're here to guide you through every step of the process.
    
    Please let us know if you'd like to explore how Pay 321 can empower your business to reach its full potential. We're excited about the opportunity to work with you.
    
    Thank you for considering Pay 321.
    `,
  };
  return emailTemplate;
};

export const waitLetter8 = (unionData) => {
  const emailTemplate = {
    subject: "Unlock the Power of Pay 321 for Your Union",
    isEmail: true,
    message: `Dear ${repName(unionData)},

    I trust this email finds you well. Pay 321 is more than just a platform – it's a catalyst for change within your business. By leveraging Pay 321's comprehensive features, you can streamline operations, foster collaboration, and strengthen member relationships.
    
    Despite our previous attempts to connect, we haven't had the chance to discuss how Pay 321 aligns with your business's goals. We're eager to demonstrate the value our platform can bring to your organization.
    
    Please don't hesitate to reach out if you're interested in exploring how Pay 321 can drive success for your company. We're here to support you every step of the way.

    Thank you for considering Pay 321.
    `,
  };
  return emailTemplate;
};

export const waitLetter9 = (unionData) => {
  const emailTemplate = {
    subject: "Revolutionize Your Union's Operations with Pay 321",
    isEmail: true,
    message: `Dear ${repName(unionData)},

    I hope this email finds you well. Pay 321 offers a comprehensive solution to streamline your business's operations, enhance member engagement, and improve communication across all levels.
    
    Despite our previous attempts to connect, we haven't had the chance to discuss how Pay 321 can address the specific challenges facing your business. We're committed to understanding your needs and tailoring our solution to meet them effectively.
    
    If you're ready to revolutionize your business's operations and drive growth, we're here to help. Let's schedule a conversation to explore how Pay 321 can make a difference for your organization.
    
    Thank you for considering Pay 321.
    `,
  };
  return emailTemplate;
};

export const waitLetter10 = (unionData) => {
  const emailTemplate = {
    subject: "Empower Your Union with Pay 321's Innovative Features",
    isEmail: true,
    message: `Hello ${repName(unionData)},

    I hope this email finds you well. Pay 321 is more than just a platform – it's a game-changer for businesses like yours. With its innovative features and user-friendly interface, Pay 321 empowers businesses to streamline operations, enhance member engagement, and achieve their organizational goals.
    
    Despite our previous attempts to connect, we haven't had the opportunity to discuss how Pay 321 can revolutionize your business's operations. We're passionate about helping businesses thrive, and we're here to support you every step of the way.
    
    If you're ready to unlock the full potential of your business, let's schedule a demo to explore how Pay 321 can make a difference for your organization.
    
    Thank you for considering Pay 321.
    `,
  };
  return emailTemplate;
};

export const waitLetter11 = (unionData) => {
  const emailTemplate = {
    subject: "Discover the Pay 321 Advantage for Your Union",
    isEmail: true,
    message: `Hello ${repName(unionData)},

    I hope you're doing well. Pay 321 offers a comprehensive solution to address the unique challenges facing businesses today. With its intuitive design and robust features, Pay 321 empowers businesses to streamline operations, strengthen member engagement, and drive organizational growth.
    
    Despite our previous attempts to connect, we haven't had the opportunity to demonstrate how Pay 321 can benefit your business. We're committed to understanding your needs and delivering tailored solutions that drive results.
    
    If you're ready to take your business to the next level, we invite you to explore the Pay 321 advantage. Schedule a consultation today to learn how Pay 321 can transform your organization.
    
    Thank you for considering Pay 321.
    `,
  };
  return emailTemplate;
};

export const waitLetter12 = (unionData) => {
  const emailTemplate = {
    subject: "Transform Your Union with Pay 321's Proven Solution",
    isEmail: true,
    message: `Hello ${repName(unionData)},

    I trust this email finds you well. Pay 321 is the solution your business has been searching for. With its powerful tools and advanced capabilities, Pay 321 empowers businesses to streamline operations, increase member engagement, and drive meaningful change.
    
    Despite our previous attempts to connect, we haven't had the opportunity to discuss how Pay 321 can support your business's goals. We're passionate about helping businesses succeed, and we're committed to providing you with the support and guidance you need to thrive.
    
    If you're ready to transform your business and unlock new opportunities for growth, we're here to help. Schedule a demo today to experience the Pay 321 difference for yourself.
    
    Thank you for considering Pay 321.
    `,
  };
  return emailTemplate;
};

export const whoLetter = (unionData) => {
  const emailTemplate = {
      subject: 'Unlocking the Potential of VoIP for Your Business',
      isEmail: true,
      message: `
          Dear ${unionData.contactName || 'Business Owner'},

          I hope this email finds you well. My name is ${fullName}, and I represent our VoIP/Internet services, a platform designed to empower unions like yours to thrive in today's dynamic landscape. As the President of your business, you play a pivotal role in guiding its success, and we believe our VoIP/Internet services can be an invaluable tool to achieve your goals.

          Allow me to introduce our VoIP/Internet services:

          What is VoIP?

          Our VoIP (Voice over Internet Protocol) services provide a reliable and efficient communication infrastructure for unions. We focus on delivering high-quality voice calls and advanced features to enhance communication among union members.

          Our Offer to You:

          We're excited to offer you an opportunity to explore our VoIP services further through a personalized demonstration. This demonstration will allow you to see firsthand how our platform can benefit your business. Whether you have questions, specific requirements, or simply want to chat and learn more about what our VoIP services can do for you, we're here to help.

          No Obligation, Just a Conversation:

          There's absolutely no obligation associated with this offer. We invite you to connect with us at your convenience for an informal chat. This conversation will give us the chance to understand your business's unique needs better, and it provides you with an opportunity to learn about our VoIP services and how they align with your objectives.

          Designed for Union Success:

          Our VoIP services are designed to meet the communication needs of unions effectively. We prioritize reliability, efficiency, and seamless communication to enhance your business's success.

          To schedule a call or request a demonstration, simply reply to this email or give us a call at ${phoneNumber}. We are flexible and will work with your schedule to ensure a convenient time.

          We look forward to the possibility of working with your business and helping you achieve greater communication efficiency. Your business's success is our priority.

          Thank you for considering our VoIP services, and we hope to hear from you soon.
      `
  };
  return emailTemplate;
};



export const whatLetter = (unionData) => {
  const emailTemplate = {
      subject: 'Unlocking the Potential of VoIP for Your Business',
      isEmail: true,
      message: `
          Dear ${unionData.contactName || 'Business Owner'},

          I trust this message finds you well. I'm ${fullName}, representing our VoIP/Internet services, a revolutionary platform poised to transform your business's operations. As the key figure steering your business's success, we believe our VoIP/Internet services can play a pivotal role in achieving your goals.

          Advanced Communication Solutions:
          Our VoIP services redefine member engagement by fostering a dynamic hub where members connect, collaborate, and stay informed. It establishes a vibrant online community, keeping members actively involved in union activities.

          Efficiency and Cost Savings:
          Our VoIP services streamline administrative tasks, from communication to event management, translating to significant cost savings. By reducing manual processes, it ensures resources are directed toward your business's core mission.

          Time Savings for Your Executives:
          Designed to save valuable time, our VoIP services offer tools simplifying member communication, grievance handling, and event organization. This empowers leadership to focus on strategic decisions and driving member engagement.

          Enhanced Member Support:
          Our platform enhances member support, leading to increased member satisfaction and engagement. It showcases positive impacts, increasing support for current leadership and improving re-election opportunities.

          Built by Experts for Union Success:
          Shaped by collaboration with over 200 businesses, our VoIP services address unique union challenges effectively.

          Follow-Up on Previous Email:
          We understand your busy schedule and appreciate your time. If you've had a chance to review our previous email, we'd love to hear your thoughts or address any questions you may have.

          No Obligation, Just a Conversation:
          Explore our VoIP services through a personalized demonstration. Whether you have questions or specific requirements, we're here to assist. To schedule, reply to this email or call ${phoneNumber}. We'll work with your schedule.

          We look forward to the possibility of working with your business, prioritizing extraordinary communication efficiency.

          Thank you for considering our VoIP services. We hope to hear from you soon.
      `
  };
  return emailTemplate;
};



export const willCallLetter = (unionData) => {
  const emailTemplate = {
      subject: 'Follow-Up: VoIP - A Personalized Call Awaits',
      isEmail: true,
      message: `
          Dear ${unionData.contactName || 'Union Contact'},

          I hope this email finds you well. I wanted to follow up on my previous email regarding our VoIP/Internet services and the potential they hold for your business.

          As mentioned earlier, my name is ${fullName}, and I represent our VoIP/Internet services. We have been eagerly looking forward to the opportunity to discuss how our VoIP/Internet services can benefit your business, and we greatly value your time.

          Scheduled Call:

          I wanted to inform you that I will be giving you a call at your earliest convenience to discuss our VoIP/Internet services in more detail. I have your contact number on file as ${phoneNumber}, and I will use this number to reach out to you.

          Your Time, Your Pace:

          Our aim is to provide you with all the information you need to make an informed decision, and this call is intended to be an open and exploratory conversation. There's no pressure or obligation on your part; it's simply an opportunity for us to learn more about your business's specific needs and to address any questions or concerns you might have.

          Learning Together:

          We believe that there is no harm in having a conversation and learning from each other. Your insights and requirements are of utmost importance to us as we strive to tailor our VoIP/Internet services to meet the unique needs of your business. Likewise, we are excited to share our expertise and how our VoIP/Internet services can contribute to the growth and efficiency of your organization.

          Please feel free to suggest a convenient time for our call, or I can propose a few times based on your availability. We are here to accommodate your schedule.

          Thank you once again for considering our VoIP/Internet services. We look forward to our conversation and the possibility of working together to enhance your business's communication efficiency.

          If you have any immediate questions or need to reach out before our scheduled call, you can also contact me at ${email}.
      `
  };
  return emailTemplate;
};


export const appointmentConfirmation = ({
  unionData,
  appointmentDate,
  startTime,
  endTime,
}) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    appointmentDate,
  );

  const timeString = unionData.timeZone
    ? `${formatTimeToLocalTime(startTime, endTime, unionData.timeZone).formattedStartTime} - ${formatTimeToLocalTime(startTime, endTime, unionData.timeZone).formattedEndTime} (${unionData.timeZone})`
    : `${startTime} - ${endTime} (Canada/Eastern)`;

  const emailTemplate = {
    subject: "Confirmation: Pay 321 Presentation Appointment",
    message: `Dear ${repName(unionData)},

        I hope this message finds you well. We are thrilled to confirm your appointment for a presentation of Pay 321, the innovative platform designed to enhance your business's capabilities and success.

        𝗔𝗽𝗽𝗼𝗶𝗻𝘁𝗺𝗲𝗻𝘁 𝗗𝗲𝘁𝗮𝗶𝗹𝘀:

        𝗗𝗮𝘁𝗲: ${formattedDate}

        𝗧𝗶𝗺𝗲: ${timeString}

        𝗗𝘂𝗿𝗮𝘁𝗶𝗼𝗻: Approximately ${calculateTimeDifference(startTime, endTime)}

        𝗣𝗿𝗲𝘀𝗲𝗻𝘁𝗲𝗿: ${loggedInUser()}

        𝗠𝗲𝗲𝘁𝗶𝗻𝗴 𝗟𝗶𝗻𝗸: https://us02web.zoom.us/j/86016070543?pwd=WVNGaDVPWlhCVHV6U0NZUkNlcmhtZz09

        𝗔𝗴𝗲𝗻𝗱𝗮:

        During our presentation, we will provide an in-depth demonstration of Pay 321's features and functionalities, showcasing how it can help increase member engagement, decrease expenses, and save valuable time for business executives and administrators. We will also be open to any questions or specific requirements you may have.

        𝗠𝗲𝗲𝘁𝗶𝗻𝗴 𝗣𝗿𝗲𝗽𝗮𝗿𝗮𝘁𝗶𝗼𝗻:

        To make the most of our time together, please ensure that you have access to a computer or device with an internet connection and audio capabilities, as well as a web browser for the virtual presentation, if applicable.

        𝗖𝗼𝗻𝘁𝗮𝗰𝘁 𝗜𝗻𝗳𝗼𝗿𝗺𝗮𝘁𝗶𝗼𝗻:

        If you have any questions or need to reschedule for any reason, please don't hesitate to reach out to us at ${email} or ${phoneNumber}. We are here to assist you in any way possible.

        𝗪𝗲 𝗟𝗼𝗼𝗸 𝗙𝗼𝗿𝘄𝗮𝗿𝗱 𝘁𝗼 𝗠𝗲𝗲𝘁𝗶𝗻𝗴 𝗬𝗼𝘂:

        We value your time and your commitment to exploring Pay 321, and we are dedicated to making this presentation informative and engaging.

        Until then, please accept our warmest regards, and we'll see you on ${formattedDate}!
        `,
    isEmail: true,
  };
  return emailTemplate;
};

export const thankYouLetter = ({ unionData }) => {
  const emailTemplate = {
    subject: "Thank You for Your Time - Pay 321 Presentation",
    isEmail: true,
    message: `Dear ${repName(unionData)},

I wanted to extend my heartfelt thanks to you for taking the time to join our presentation on Pay 321. It was a pleasure meeting with you and discussing how our platform can benefit your business.

Your engagement and thoughtful questions during the presentation were greatly appreciated. We are genuinely excited about the possibility of partnering with your business to help you achieve your goals of increasing member engagement, reducing expenses, and saving valuable time for your business executives and administrators.

Key Takeaways from the Presentation:

During our meeting, we highlighted the key features and functionalities of Pay 321, showcasing how it can transform your business's operations and enhance its overall effectiveness. We also addressed your specific questions and requirements, which are invaluable in tailoring our solutions to meet your needs.

Next Steps:

We understand that decisions like these require careful consideration. You will find the presentation materials attached to this email for your reference. If you have any further questions, need additional information, or would like to discuss anything in more detail, please do not hesitate to reach out to us. We are here to assist you every step of the way.

Your Input Matters:

Your feedback and input are of the utmost importance to us. As we move forward, we look forward to collaborating with you and your team to ensure that Pay 321 is aligned perfectly with your business's vision and objectives.

Stay Connected:

To keep you updated on the latest developments and news about Pay 321, we encourage you to connect with us on social media and subscribe to our newsletter. This way, you can stay informed about how our platform is making a difference in the world of businesses.

Thank you once again for considering Pay 321 as a potential partner for your business. We are excited about the possibility of working together and contributing to your business's continued success.
        `,
  };
  return emailTemplate;
};
