import React, { useState } from "react";
import axios from "axios";
import { changeTaskUnionLocal } from "../../helpers/taskActions";

import "../../css/tab.css";
import DateInput from "../inputs/DateInput";

const EditModal = ({ closeModal, clientData, refreshSuspectList, setData }) => {
  const [selectedContactPerson, setSelectedContactPerson] = useState(1);
  const [client, setClient] = useState({
    union: clientData.union || "",
    localNumber: clientData.localNumber || " ",
    officePhoneNumber: clientData.officePhoneNumber || "",
    website: clientData.website || "",
    faxNumber: clientData.faxNumber || "",
    email: clientData.email || "",
    memberSize: clientData.memberSize || "",
    province: clientData.province || "",
    postal: clientData.postal || "",
    address: clientData.address || "",
    city: clientData.city || "",
    revenue: clientData.revenue || "",
    contactName: clientData.contactName || "",
    contactJobTitle: clientData.contactJobTitle || "",
    contactNumber1: clientData.contactNumber1 || "",
    contactNumber2: clientData.contactNumber2 || "",
    contactBirthDay: clientData.contactBirthDay || "",
    contactEmail: clientData.contactEmail || "",
    contactGender: clientData.contactGender || "",
    alternateContactName: clientData.alternateContactName || "",
    alternateContactJobTitle: clientData.alternateContactJobTitle || "",
    alternateContactNumber1: clientData.alternateContactNumber1 || "",
    alternateContactNumber2: clientData.alternateContactNumber2 || "",
    alternateContactBirthDay: clientData.alternateContactBirthDay || "",
    alternateContactEmail: clientData.alternateContactEmail || "",
    alternateContactGender: clientData.alternateContactGender || "",
    electionCycle: clientData.electionCycle || "",
    electionDate: clientData.electionDate || "",
    bargainingUnits: clientData.bargainingUnits || "",
    timeZone: clientData.timeZone || "",
    notes: clientData.notes || "",
  });

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    setClient((prevClient) => ({
      ...prevClient,
      [fieldName]: value,
    }));
  };
  const handlePrimaryContactChange = (contactPerson) => {
    setClient((prevClient) => ({
      ...prevClient,
      primaryContact: contactPerson,
    }));
    console.log(contactPerson);
  };
  const handleUpdate = () => {
    // Define the URL for the PUT request with the appropriate ID
    const apiUrl = `https://apijonluca.netlify.app/.netlify/functions/api/unions/${clientData._id}`;

    // Send a PUT request to update the data
    axios
      .put(apiUrl, client)
      .then((response) => {
        // Handle success, e.g., close the modal
        console.log("Data updated successfully:", response.data);
        setData(response.data);
        closeModal();
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating data:", error);
      });
    changeTaskUnionLocal({ id: clientData._id, unionData: client });
    refreshSuspectList();
    // Refresh the current page
  };


  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-2/3 xl:w-1/2 overflow-y-auto relative">
        <button
          onClick={closeModal}
          className="absolute top-2 right-2 p-2 text-gray-600 hover:text-gray-800"
        >
          <span className="text-3xl">&times;</span>
        </button>
        <h2 className="text-xl font-semibold mb-4 p-2">Edit {clientData.union}</h2>
        <div className="h-100 overflow-y-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div>
              <p className={`text-sm mb-1 ${!client.union && "font-bold"}`}>
                Company {!client.union && "⛔"}
              </p>
              <input
                type="text"
                placeholder="Union"
                value={client.union}
                onChange={(e) => handleInputChange(e, "union")}
                className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.union && "bg-red-100"}`}
              />
            </div>
            {/* <div>
              <p
                className={`text-sm mb-1 ${!client.localNumber && "font-bold"}`}
              >
                Local Number {!client.localNumber && "⛔"}
              </p>
              <input
                type="text"
                placeholder="Local Number"
                value={client.localNumber}
                onChange={(e) => handleInputChange(e, "localNumber")}
                className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.localNumber && "bg-red-100"}`}
              />
            </div> */}
            <div>
              <p
                className={`text-sm mb-1 ${!client.officePhoneNumber && "font-bold"}`}
              >
                Office Phone Number {!client.officePhoneNumber && "⛔"}
              </p>
              <input
                type="text"
                placeholder="Office Phone Number"
                value={client.officePhoneNumber}
                onChange={(e) => handleInputChange(e, "officePhoneNumber")}
                className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.officePhoneNumber && "bg-red-100"}`}
              />
            </div>
            <div>
              <p className={`text-sm mb-1 ${!client.website && "font-bold"}`}>
                Website {!client.website && "⛔"}
              </p>
              <input
                type="text"
                placeholder="Website"
                value={client.website}
                onChange={(e) => handleInputChange(e, "website")}
                className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.website && "bg-red-100"}`}
              />
            </div>

            <div>
              <p className={`text-sm mb-1 ${!client.faxNumber && "font-bold"}`}>
                Fax Number {!client.faxNumber && "⛔"}
              </p>
              <input
                type="text"
                placeholder="Fax Number"
                value={client.faxNumber}
                onChange={(e) => handleInputChange(e, "faxNumber")}
                className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.faxNumber && "bg-red-100"}`}
              />
            </div>
            <div>
              <p
                className={`text-sm mb-1 ${!client.localNumber && "font-bold"}`}
              >
                Local Email {!client.email && "⛔"}
              </p>
              <input
                type="text"
                placeholder="Email"
                value={client.email}
                onChange={(e) => handleInputChange(e, "email")}
                className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.email && "bg-red-100"}`}
              />
            </div>
            <div>
              <p
                className={`text-sm mb-1 ${!client.memberSize && "font-bold"}`}
              >
                Member Size {!client.memberSize && "⛔"}
              </p>
              <input
                type="text"
                placeholder="Member Size"
                value={client.memberSize}
                onChange={(e) => handleInputChange(e, "memberSize")}
                className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.memberSize && "bg-red-100"}`}
              />
            </div>
            <div>
            <p className={`text-sm mb-1 ${!client.province && "font-bold"}`}>
                Province/State {!client.province && "⛔"}
              </p>
              <select
                value={client.province}
                onChange={(e) => handleInputChange(e, "province")}
                className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.province && "bg-red-100"}`}
              >
                <option value="">Select Province/State</option>
                <optgroup label="Canada">
  <option value="AB">Alberta</option>
  <option value="BC">British Columbia</option>
  <option value="MB">Manitoba</option>
  <option value="NB">New Brunswick</option>
  <option value="NL">Newfoundland and Labrador</option>
  <option value="NS">Nova Scotia</option>
  <option value="ON">Ontario</option>
  <option value="PE">Prince Edward Island</option>
  <option value="QC">Quebec</option>
  <option value="SK">Saskatchewan</option>
  <option value="NT">Northwest Territories</option>
  <option value="NU">Nunavut</option>
  <option value="YT">Yukon</option>
</optgroup>
                  <optgroup label="United States">
  <option value="AL">Alabama</option>
  <option value="AK">Alaska</option>
  <option value="AZ">Arizona</option>
  <option value="AR">Arkansas</option>
  <option value="CA">California</option>
  <option value="CO">Colorado</option>
  <option value="CT">Connecticut</option>
  <option value="DE">Delaware</option>
  <option value="FL">Florida</option>
  <option value="GA">Georgia</option>
  <option value="HI">Hawaii</option>
  <option value="ID">Idaho</option>
  <option value="IL">Illinois</option>
  <option value="IN">Indiana</option>
  <option value="IA">Iowa</option>
  <option value="KS">Kansas</option>
  <option value="KY">Kentucky</option>
  <option value="LA">Louisiana</option>
  <option value="ME">Maine</option>
  <option value="MD">Maryland</option>
  <option value="MA">Massachusetts</option>
  <option value="MI">Michigan</option>
  <option value="MN">Minnesota</option>
  <option value="MS">Mississippi</option>
  <option value="MO">Missouri</option>
  <option value="MT">Montana</option>
  <option value="NE">Nebraska</option>
  <option value="NV">Nevada</option>
  <option value="NH">New Hampshire</option>
  <option value="NJ">New Jersey</option>
  <option value="NM">New Mexico</option>
  <option value="NY">New York</option>
  <option value="NC">North Carolina</option>
  <option value="ND">North Dakota</option>
  <option value="OH">Ohio</option>
  <option value="OK">Oklahoma</option>
  <option value="OR">Oregon</option>
  <option value="PA">Pennsylvania</option>
  <option value="RI">Rhode Island</option>
  <option value="SC">South Carolina</option>
  <option value="SD">South Dakota</option>
  <option value="TN">Tennessee</option>
  <option value="TX">Texas</option>
  <option value="UT">Utah</option>
  <option value="VT">Vermont</option>
  <option value="VA">Virginia</option>
  <option value="WA">Washington</option>
  <option value="WV">West Virginia</option>
  <option value="WI">Wisconsin</option>
  <option value="WY">Wyoming</option>
</optgroup>

              </select>
            </div>
            <div>
              <p className={`text-sm mb-1 ${!client.postal && "font-bold"}`}>
                Postal Code/Area Code {!client.postal && "⛔"}
              </p>
              <input
                type="text"
                placeholder="Postal Code/Area Code"
                value={client.postal}
                onChange={(e) => handleInputChange(e, "postal")}
                className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.postal && "bg-red-100"}`}
              />
            </div>
            <div>
              <p className={`text-sm mb-1 ${!client.address && "font-bold"}`}>
                Address {!client.address && "⛔"}
              </p>
              <input
                type="text"
                placeholder="Address"
                value={client.address}
                onChange={(e) => handleInputChange(e, "address")}
                className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.address && "bg-red-100"}`}
              />
            </div>
            <div>
              <p className={`text-sm mb-1 ${!client.city && "font-bold"}`}>
                City {!client.city && "⛔"}
              </p>
              <input
                type="text"
                placeholder="City"
                value={client.city}
                onChange={(e) => handleInputChange(e, "city")}
                className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.city && "bg-red-100"}`}
              />
            </div>
            <div>
              <p className={`text-sm mb-1 ${!client.revenue && "font-bold"}`}>
                Revenue {!client.revenue && "⛔"}
              </p>
              <input
                type="text"
                placeholder="Revenue"
                value={client.revenue}
                onChange={(e) => handleInputChange(e, "revenue")}
                className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.revenue && "bg-red-100"}`}
              />
            </div>
            <div>
              <p
                className={`text-sm mb-1 ${!client.electionCycle && "font-bold"}`}
              >
                Election Cycle {!client.electionCycle && "⛔"}
              </p>
              <input
                type="text"
                placeholder="Election Cycle"
                value={client.electionCycle}
                onChange={(e) => handleInputChange(e, "electionCycle")}
                className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.electionCycle && "bg-red-100"}`}
              />
            </div>
            <div>
              <p
                className={`text-sm mb-1 ${!client.electionDate && "font-bold"}`}
              >
                Election Date {!client.electionDate && "⛔"}
              </p>
              <div className="p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full">
                  <DateInput chosenDate={client.electionDate} setClient={setClient} property="electionDate"/>
                  </div>
            </div>
            <div>
              <p
                className={`text-sm mb-1 ${!client.bargainingUnits && "font-bold"}`}
              >
                Bargaining Units {!client.bargainingUnits && "⛔"}
              </p>
              <input
                type="text"
                placeholder="Bargaining Units"
                value={client.bargainingUnits}
                onChange={(e) => handleInputChange(e, "bargainingUnits")}
                className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.bargainingUnits && "bg-red-100"}`}
              />
            </div>
            <div>
              <p className={`text-sm mb-1 ${!client.timeZone && "font-bold"}`}>
                Time Zone {!client.timeZone && "⛔"}
              </p>
              <select
                value={client.timeZone}
                onChange={(e) => handleInputChange(e, "timeZone")}
                className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.timeZone && "bg-red-100"}`}
              >
                <option value="">Select Time Zone</option>
                <option value="Canada/Atlantic">Canada/Atlantic</option>
                <option value="Canada/Central">Canada/Central</option>
                <option value="Canada/Eastern">Canada/Eastern</option>
                <option value="Canada/Mountain">Canada/Mountain</option>
                <option value="Canada/Newfoundland">Canada/Newfoundland</option>
                <option value="Canada/Pacific">Canada/Pacific</option>
                <option value="Canada/Saskatchewan">Canada/Saskatchewan</option>
                <option value="Canada/Yukon">Canada/Yukon</option>
                <option value="America/Chicago">America/Chicago</option>
                <option value="America/Anchorage">America/Anchorage</option>
                <option value="America/Phoenix">America/Phoenix</option>
                <option value="America/Los_Angeles">America/Los_Angeles</option>
                <option value="America/Denver">America/Denver</option>
                <option value="America/New_York">America/New_York</option>
                <option value="Pacific/Honolulu">Pacific/Honolulu</option>
              </select>
            </div>
          </div>

          <div className="flex gap-x-8">
            <p
              className={`text-lg font-semibold mb-2 mt-4 cursor-pointer tab ${
                selectedContactPerson === 1 &&
                "text-blue-500 underline active-tab"
              }`}
              onClick={() => setSelectedContactPerson(1)}
            >
              Contact Person 1
            </p>

            <p
              className={`text-lg font-semibold mb-2 mt-4 cursor-pointer tab ${
                selectedContactPerson === 2 &&
                "text-blue-500 underline active-tab"
              }`}
              onClick={() => setSelectedContactPerson(2)}
            >
              Contact Person 2
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {selectedContactPerson === 1 ? (
              <>
                <div>
                  <p
                    className={`text-sm mb-1 ${!client.contactName && "font-bold"}`}
                  >
                    Full Name {!client.contactName && "⛔"}
                  </p>
                  <input
                    type="text"
                    placeholder="Name"
                    value={client.contactName}
                    onChange={(e) => handleInputChange(e, "contactName")}
                    className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.contactName && "bg-red-100"}`}
                  />
                </div>
                <div>
                  <p
                    className={`text-sm mb-1 ${!client.contactJobTitle && "font-bold"}`}
                  >
                    Job Title {!client.contactJobTitle && "⛔"}
                  </p>
                  <input
                    type="text"
                    placeholder="Job Title"
                    value={client.contactJobTitle}
                    onChange={(e) => handleInputChange(e, "contactJobTitle")}
                    className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.contactJobTitle && "bg-red-100"}`}
                  />
                </div>
                <div>
                  <p
                    className={`text-sm mb-1 ${!client.contactNumber1 && "font-bold"}`}
                  >
                    Cell Phone Number {!client.contactNumber1 && "⛔"}
                  </p>
                  <input
                    type="text"
                    placeholder="Cell Phone Number"
                    value={client.contactNumber1}
                    onChange={(e) => handleInputChange(e, "contactNumber1")}
                    className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.contactNumber1 && "bg-red-100"}`}
                  />
                </div>
                <div>
                  <p
                    className={`text-sm mb-1 ${!client.contactNumber2 && "font-bold"}`}
                  >
                    Home Phone Number {!client.contactNumber2 && "⛔"}
                  </p>
                  <input
                    type="text"
                    placeholder="Home Phone Number"
                    value={client.contactNumber2}
                    onChange={(e) => handleInputChange(e, "contactNumber2")}
                    className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.contactNumber2 && "bg-red-100"}`}
                  />
                </div>
                <div>
                  <p
                    className={`text-sm mb-1 ${!client.contactBirthDay && "font-bold"}`}
                  >
                    Birth Day {!client.contactBirthDay && "⛔"}
                  </p>
                  
                  <div className="p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full">
                  <DateInput chosenDate={client.contactBirthDay} setClient={setClient} property="contactBirthDay"/>
                  </div>
                  
                </div>
                <div>
                  <p
                    className={`text-sm mb-1 ${!client.contactEmail && "font-bold"}`}
                  >
                    Contact Email {!client.contactEmail && "⛔"}
                  </p>
                  <input
                    type="text"
                    placeholder="Email"
                    value={client.contactEmail}
                    onChange={(e) => handleInputChange(e, "contactEmail")}
                    className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.contactEmail && "bg-red-100"}`}
                  />
                </div>
                <div>
                  <p
                    className={`text-sm mb-1 ${!client.contactGender && "font-bold"}`}
                  >
                    Gender {!client.contactGender && "⛔"}
                  </p>
                  <select
                    value={client.contactGender}
                    onChange={(e) => handleInputChange(e, "contactGender")}
                    className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.contactGender && "bg-red-100"}`}
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="flex">
                  <button
                    className="p-2 bg-blue-500 text-white self-end rounded-md shadow-md hover:bg-blue-600 active:bg-blue-700"
                    onClick={() => handlePrimaryContactChange(1)}
                  >
                    Set as default contact
                  </button>
                </div>
              </>
            ) : (
              <>
                <div>
                  <p
                    className={`text-sm mb-1 ${!client.alternateContactName && "font-bold"}`}
                  >
                    Full Name {!client.alternateContactName && "⛔"}
                  </p>
                  <input
                    type="text"
                    placeholder="Name"
                    value={client.alternateContactName}
                    onChange={(e) =>
                      handleInputChange(e, "alternateContactName")
                    }
                    className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.alternateContactName && "bg-red-100"}`}
                  />
                </div>
                <div>
                  <p
                    className={`text-sm mb-1 ${!client.alternateContactJobTitle && "font-bold"}`}
                  >
                    Job Title {!client.alternateContactJobTitle && "⛔"}
                  </p>
                  <input
                    type="text"
                    placeholder="Job Title"
                    value={client.alternateContactJobTitle}
                    onChange={(e) =>
                      handleInputChange(e, "alternateContactJobTitle")
                    }
                    className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.alternateContactJobTitle && "bg-red-100"}`}
                  />
                </div>
                <div>
                  <p
                    className={`text-sm mb-1 ${!client.alternateContactNumber1 && "font-bold"}`}
                  >
                    Cell Phone Number {!client.alternateContactNumber1 && "⛔"}
                  </p>
                  <input
                    type="text"
                    placeholder="Cell Phone Number"
                    value={client.alternateContactNumber1}
                    onChange={(e) =>
                      handleInputChange(e, "alternateContactNumber1")
                    }
                    className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.alternateContactNumber1 && "bg-red-100"}`}
                  />
                </div>
                <div>
                  <p
                    className={`text-sm mb-1 ${!client.alternateContactNumber2 && "font-bold"}`}
                  >
                    Home Phone Number {!client.alternateContactNumber2 && "⛔"}
                  </p>
                  <input
                    type="text"
                    placeholder="Home Phone Number"
                    value={client.alternateContactNumber2}
                    onChange={(e) =>
                      handleInputChange(e, "alternateContactNumber2")
                    }
                    className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.alternateContactNumber2 && "bg-red-100"}`}
                  />
                </div>
                <div>
                  <p
                    className={`text-sm mb-1 ${!client.alternateContactBirthDay && "font-bold"}`}
                  >
                    Birth Day {!client.alternateContactBirthDay && "⛔"}
                  </p>
                  
                  <input
                    type="text"
                    placeholder="Birth Day"
                    value={client.alternateContactBirthDay}
                    onChange={(e) => handleInputChange(e, "alternateContactBirthDay")}
                    className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.alternateContactBirthDay && "bg-red-100"}`}
                  />
                  
                </div>
                <div>
                  <p
                    className={`text-sm mb-1 ${!client.alternateContactEmail && "font-bold"}`}
                  >
                    Contact Email {!client.alternateContactEmail && "⛔"}
                  </p>
                  <input
                    type="text"
                    placeholder="Email"
                    value={client.alternateContactEmail}
                    onChange={(e) =>
                      handleInputChange(e, "alternateContactEmail")
                    }
                    className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.alternateContactEmail && "bg-red-100"}`}
                  />
                </div>
                <div>
                  <p
                    className={`text-sm mb-1 ${!client.alternateContactGender && "font-bold"}`}
                  >
                    Gender {!client.alternateContactGender && "⛔"}
                  </p>
                  <select
                    value={client.alternateContactGender}
                    onChange={(e) =>
                      handleInputChange(e, "alternateContactGender")
                    }
                    className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full ${!client.alternateContactGender && "bg-red-100"}`}
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="flex">
                  <button
                    className="p-2 bg-blue-500 text-white self-end rounded-md shadow-md hover:bg-blue-600 active:bg-blue-700"
                    onClick={() => handlePrimaryContactChange(2)}
                  >
                    Set as default contact
                  </button>
                </div>
              </>
            )}

            <div className="col-span-4">
              <p className={`text-sm mb-1`}>Notes</p>
              <textarea
                rows="4"
                placeholder="Notes"
                value={client.notes}
                onChange={(e) => handleInputChange(e, "notes")}
                className={`p-2 border rounded focus:outline-none focus:ring focus:border-blue-500 w-full`}
              ></textarea>
            </div>
          </div>
        </div>
        <hr className="my-4 border-gray-300" />
        <div className="flex justify-end mt-6 gap-2">
          <button
            onClick={closeModal}
            className="px-4 py-2 text-gray-600 border border-gray-300 rounded hover:bg-gray-100 focus:outline-none"
          >
            Cancel
          </button>
          <button
            onClick={handleUpdate}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
