import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import Calendar from "../Calendar";

import { formatDate, nextBusinessDay } from "../../helpers/formatDate";
import { RedButton, GreenButton } from "../buttons/button";
import {
  createWaitPhoneCall,
  checkIfWaitCallExists,
  createWaitTasks,
} from "../../helpers/strategies/wait";

// import { noLetter1, noLetter2, noLetter3 } from '../../helpers/scriptTemplates/emailTemplates';
// import {FaEnvelope } from 'react-icons/fa';

export default function WaitModal({
  closeModal,
  data,
  refreshSuspectList,
  tasks,
}) {
  const [scheduleDays, setScheduleDays] = useState("1");
  const [showCalendar, setShowCalendar] = useState(false);
  const [waitCallExists, setWaitCallExists] = useState(false);
  // const [apiTasks, setApiTasks] = useState([]);
  const [isFullModal, setIsFullModal] = useState(false);
  const [scheduleDate, setScheduleDate] = useState();

  const [selectedContact, setSelectedContact] = useState(
    data.primaryContact || 1,
  );

  useEffect(() => {
    const fetchData = async () => {
      const exists = await checkIfWaitCallExists(data);
      setWaitCallExists(exists);
    };

    fetchData();
  }, [data]);

  const handleRadioChange = (e) => {
    setScheduleDays(e.target.value);
  };

  const handleShowCalendar = () => {
    setShowCalendar(true);
    setIsFullModal(true);
  };

  const handleCloseCalendar = () => {
    setShowCalendar(false);
    setIsFullModal(false);
  };

  const confirmSchedule = ({ data, nextContact }) => {
    closeModal();
    createWaitPhoneCall({
      data: data,
      nextContact: nextContact,
      refreshSuspectList: refreshSuspectList,
    }); // Leaving note blank as an empty string
  };

  const activateWaitStrategy = ({ data, nextContact }) => {
    closeModal();
    createWaitTasks({
      data: data,
      nextContact: nextContact,
      refreshSuspectList: refreshSuspectList,
    }); // Leaving note blank as an empty string
  };

  return (
    <Modal closeModal={closeModal} fullModal={isFullModal}>
      <h2 className="text-xl font-bold">Wait Strategy</h2>
      {!waitCallExists ? (
        <>
          {showCalendar ? (
            <Calendar
              tasks={tasks}
              onCloseCalendar={handleCloseCalendar}
              setScheduleDate={setScheduleDate}
            />
          ) : (
            <div>
              {scheduleDate ? (
                <div className="gap-x-2 flex items-center">
                  <p>
                    Call back will be scheduled to:{" "}
                    <span className="font-bold">
                      {formatDate(scheduleDate)}
                    </span>
                  </p>
                  <button
                    className="bg-red-500 p-2 rounded-lg text-lg text-white font-bold"
                    onClick={() => setScheduleDate(null)}
                  >
                    Change
                  </button>
                </div>
              ) : (
                <div className="flex gap-x-16 justify-start items-center">
                  <div className="">
                    <label className="block text-lg text-gray-700 text-lg font-bold mb-2">
                      Let's schedule a phone call first
                    </label>
                    <div className="flex">
                      {[1, 2, 3, 4, 5].map((day) => (
                        <div key={day} className="mr-4">
                          <input
                            type="radio"
                            id={`${day}Day`}
                            name="scheduleDays"
                            value={day.toString()}
                            checked={scheduleDays === day.toString()}
                            onChange={handleRadioChange}
                            className="mr-1"
                          />
                          <label htmlFor={`${day}Day`}>{day}</label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="">Or</div>
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={handleShowCalendar}
                  >
                    Select Date
                  </button>
                </div>
              )}

              <div className="flex gap-x-2 mt-16 justify-end">
                <RedButton action={closeModal}>Cancel</RedButton>
                <GreenButton
                  action={() =>
                    confirmSchedule({
                      data: data,
                      nextContact:
                        scheduleDate || nextBusinessDay(scheduleDays),
                    })
                  }
                >
                  Schedule Call Back
                </GreenButton>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="gap-x-2 flex-col items-center w-full">
          <p className="">Ready to activate the wait strategy?</p>

          <div className="mt-4">
            <label className="block text-lg text-gray-700 font-semibold">
              Select Contact
            </label>
            <div className="flex mt-2">
              <label className="inline-flex items-center mr-4">
                <input
                  type="radio"
                  value={1}
                  checked={selectedContact === 1}
                  onChange={() => setSelectedContact(1)}
                  className="form-radio h-5 w-5 text-lg text-blue-500"
                  disabled={data.contactEmail != null ? false : true}
                />
                <p className="ml-2">
                  {data.contactName
                    ? `${data.contactName} ${data.contactJobTitle && `(${data.contactJobTitle})`}`
                    : `Contact 1`}{" "}
                  {!data.contactEmail && (
                    <span className="font-bold">⚠️ Missing email</span>
                  )}
                </p>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value={2}
                  checked={selectedContact === 2}
                  onChange={() => setSelectedContact(2)}
                  className="form-radio h-5 w-5 text-lg text-blue-500"
                  disabled={data.alternateContactEmail != null ? false : true}
                />
                <p className="ml-2">
                  {data.alternateContactName
                    ? `${data.alternateContactName} ${data.alternateContactJobTitle && `(${data.alternateContactJobTitle})`}`
                    : `Contact 2`}{" "}
                  {!data.alternateContactEmail && (
                    <span className="font-bold">⚠️ Missing email</span>
                  )}
                </p>
              </label>
            </div>
          </div>

          {/* wait letter buttons */}
          {/* <div className='flex gap-x-4 mt-2'>
      <button className='mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'>
      <a target='_blank'  rel="noreferrer" href={`https://outlook.office.com/mail/deeplink/compose?to=${selectedContact===1?data.contactEmail:data.alternateContactEmail}&subject=${encodeURIComponent(noLetter1(data).subject)}&body=${encodeURIComponent(noLetter1(data).message)}`} className='flex items-center gap-x-2'><FaEnvelope/>Send 'No Budget' Email</a>
      </button>

      <button className='mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'>
      <a target='_blank'  rel="noreferrer" href={`https://outlook.office.com/mail/deeplink/compose?to=${selectedContact===1?data.contactEmail:data.alternateContactEmail}&subject=${encodeURIComponent(noLetter2(data).subject)}&body=${encodeURIComponent(noLetter2(data).message)}`} className='flex items-center gap-x-2'><FaEnvelope/>Send 'Not Interested' Email</a>
      </button>

      <button className='mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'>
      <a target='_blank'  rel="noreferrer" href={`https://outlook.office.com/mail/deeplink/compose?to=${selectedContact===1?data.contactEmail:data.alternateContactEmail}&subject=${encodeURIComponent(noLetter3(data).subject)}&body=${encodeURIComponent(noLetter3(data).message)}`} className='flex items-center gap-x-2'><FaEnvelope/>Send 'Not Currently Needed' Email</a>
      </button>
      </div> */}

          <div className="flex gap-x-2 mt-16 justify-end w-full">
            <RedButton action={closeModal}>Cancel</RedButton>
            <GreenButton
              action={() =>
                activateWaitStrategy({
                  data: data,
                  nextContact: scheduleDate || nextBusinessDay(scheduleDays),
                })
              }
            >
              Activate Wait Strategy
            </GreenButton>
          </div>
        </div>
      )}
    </Modal>
  );
}
