import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

export default function YTDReportContent({data, salesReps, setSelectedRep, selectedRep, isAdmin}) {
  const currentYear = new Date().getFullYear().toString();
  const currentMonth = new Date().getMonth() + 1; // getMonth returns 0-11, adding 1 to make it 1-12

  // Creating month labels dynamically based on current month
  const monthLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].slice(0, currentMonth);

  const [chartData, setChartData] = useState({
    labels: monthLabels,
    datasets: []
  });

  useEffect(() => {
    const filteredData = data.filter(entry => entry.date.startsWith(currentYear));
    const monthlyData = Object.fromEntries(monthLabels.map((_, i) => [`${i+1}`.padStart(2, '0'), { callsMade: 0, emailsSent: 0, packagesSent:0, demosBooked:0 }]));

    filteredData.forEach(day => {
      const month = day.date.split('-')[1]; // Extract month from the date
      if (monthlyData.hasOwnProperty(month)) {
        day.users.forEach(user => {
          if (user.name.toLowerCase() === selectedRep.toLowerCase()) {
            if (user.callsMade) {
              monthlyData[month].callsMade += user.callsMade;
            }
            if (user.emailsSent) {
              monthlyData[month].emailsSent += user.emailsSent;
            }
            if (user.packagesSent) {
              monthlyData[month].packagesSent += user.packagesSent;
            }
            if (user.demosBooked) {
              monthlyData[month].demosBooked += user.demosBooked;
            }
          }
        });
      }
    });

    const callsMade = [];
    const emailsSent = [];
    const packagesSent = [];
    const demosBooked = [];
    const totalKPIs = [];

    Object.values(monthlyData).forEach(month => {
      callsMade.push(month.callsMade);
      emailsSent.push(month.emailsSent);
      packagesSent.push(month.packagesSent);
      demosBooked.push(month.demosBooked);
      totalKPIs.push(month.callsMade + month.emailsSent+month.demosBooked+month.packagesSent); // Total Monthly KPI
    });

    const newChartData = {
      labels: monthLabels,
      datasets: [
        {
          label: 'Calls Made',
          data: callsMade,
          fill: false,
          backgroundColor: 'rgb(255, 99, 132)', // bright pink for strong contrast
          borderColor: 'rgba(255, 99, 132, 0.6)',
        },
        {
          label: 'Emails Sent',
          data: emailsSent,
          fill: false,
          backgroundColor: 'rgb(54, 162, 235)', // bright blue
          borderColor: 'rgba(54, 162, 235, 0.6)',
        },
        {
          label: 'Packages Sent',
          data: packagesSent,
          fill: false,
          backgroundColor: 'rgb(255, 206, 86)', // bright yellow
          borderColor: 'rgba(255, 206, 86, 0.6)',
        },
        {
          label: 'Demos Booked',
          data: demosBooked,
          fill: false,
          backgroundColor: 'rgb(75, 192, 192)', // teal, kept for distinction
          borderColor: 'rgba(75, 192, 192, 0.6)',
        },
        {
          label: 'Total Monthly KPI',
          data: totalKPIs,
          fill: false,
          
          borderColor: 'red',
          backgroundColor: 'red',
        },
        {
          label: 'Monthly Goal',
          data: Array(data.length).fill(650), // Creates an array filled with 650 for each month
          backgroundColor: 'rgb(30, 35, 42)',
          borderColor: 'rgba(30, 35, 42, 0.7)',
          borderWidth: 2,
          type: 'line', // Ensures this dataset is a line chart
          fill: false,
        }
      ]
    };

    setChartData(newChartData);
  }, [selectedRep, data, currentMonth, currentYear, monthLabels]);

  const options = {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {isAdmin && <div>
          <label className="block text-sm font-medium text-gray-700">Sales Representative:</label>
          <select
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            value={selectedRep}
            onChange={(e) => setSelectedRep(e.target.value)}>

            {salesReps.map((rep, index) => (
              <option key={index} value={rep}>{rep}</option>
            ))}
          </select>
        </div>}
      </div>
      <Line data={chartData} options={options} />
    </div>
  );
}