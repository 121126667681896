import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

export default function DateInput({ chosenDate, setClient, property }) {
  const [startDate, setStartDate] = useState(chosenDate ? new Date(chosenDate) : null);

  const handleDateChange = (date) => {
    // Remove the time part and format the date
    const formattedDate = date?date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }):null
  
    setStartDate(date);
    setClient((prevClient) => ({ ...prevClient, [property]: formattedDate }));
  
    console.log(formattedDate);
  };
  

  return (
    <DatePicker
      selected={startDate}
      onChange={(date) => handleDateChange(date)}
      dateFormat="dd/MM/yyyy" // Customize the date format as needed
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
    />
  );
}
