import React, { useState } from "react";
import Modal from "./Modal";
import Calendar from "../Calendar";

import { formatDate, nextBusinessDay } from "../../helpers/formatDate";
import { RedButton, GreenButton } from "../buttons/button";
import { makeProspect } from "../../helpers/strategies/infopak";

export default function MakeProspectModal({
  closeModal,
  data,
  refreshSuspectList,
  tasks,
}) {
  const [rescheduleDays, setRescheduleDays] = useState("3");
  const [showCalendar, setShowCalendar] = useState(false);
  // const [apiTasks, setApiTasks] = useState([]);
  const [isFullModal, setIsFullModal] = useState(false);
  const [scheduleDate, setScheduleDate] = useState();

  const handleRadioChange = (e) => {
    setRescheduleDays(e.target.value);
  };

  const handleShowCalendar = () => {
    setShowCalendar(true);
    setIsFullModal(true);
  };

  const handleCloseCalendar = () => {
    setShowCalendar(false);
    setIsFullModal(false);
  };

  const confirmReschedule = ({ data, id, nextContact }) => {
    closeModal();
    makeProspect({ data, id, nextContact, refreshSuspectList }); // Leaving note blank as an empty string
  };

  return (
    <Modal closeModal={closeModal} fullModal={isFullModal}>
      <h2 className="text-xl font-bold">{`Choose Contact Date`}</h2>
      {showCalendar ? (
        <Calendar
          tasks={tasks}
          onCloseCalendar={handleCloseCalendar}
          setScheduleDate={setScheduleDate}
        />
      ) : (
        <div>
          {scheduleDate ? (
            <div className="gap-x-2 flex items-center">
              <p>
                Call back will be scheduled to:{" "}
                <span className="font-bold">{formatDate(scheduleDate)}</span>
              </p>
              <button
                className="bg-red-500 p-2 rounded-lg text-white font-bold"
                onClick={() => setScheduleDate(null)}
              >
                Change
              </button>
            </div>
          ) : (
            <div className="flex gap-x-16 justify-start items-center">
              <div className="">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Schedule Follow Up Call
                </label>
                <div className="flex">
                  {[1, 2, 3, 4, 5].map((day) => (
                    <div key={day} className="mr-4">
                      <input
                        type="radio"
                        id={`${day}Day`}
                        name="rescheduleDays"
                        value={day.toString()}
                        checked={rescheduleDays === day.toString()}
                        onChange={handleRadioChange}
                        className="mr-1"
                      />
                      <label htmlFor={`${day}Day`}>{day}</label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="">Or</div>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handleShowCalendar}
              >
                Select Date
              </button>
            </div>
          )}
          <div className="flex gap-x-2 mt-16 justify-end">
            <RedButton action={closeModal}>Cancel</RedButton>
            <GreenButton
              action={() =>
                confirmReschedule({
                  data: data,
                  id: data._id,
                  nextContact: scheduleDate || nextBusinessDay(rescheduleDays),
                })
              }
            >
              Schedule Follow Up
            </GreenButton>
          </div>
        </div>
      )}
    </Modal>
  );
}
