import axios from "axios";

export function deleteLead(id) {
  return axios
    .delete(`https://apijonluca.netlify.app/.netlify/functions/api/unions/${id}`)
    .then((response) => {
      if (response.status === 204) {
        console.log(`Lead with ID ${id} has been successfully deleted.`);
        // You can return a success message or handle it as needed.
      } else {
        console.error("Failed to delete the lead.");
        // You can return an error message or handle it as needed.
      }
    })
    .catch((error) => {
      console.error("An error occurred:", error);
      // You can return an error message or handle it as needed.
    });
}
