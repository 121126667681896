import React, { useState, useEffect } from 'react';
import Navbar from '../components/navigation/Navbar';
import axios from 'axios';
import MonthlyReportContent from '../components/content/MonthlyReportContent';
import YTDReportContent from '../components/content/YTDReportContent'; // Assuming you have this component

export default function Reports({ salesReps }) {
  const loggedInUser = localStorage.getItem("loggedInUser");
  const [selectedRep, setSelectedRep] = useState('');
  const [data, setData] = useState([]);
  const [reportType, setReportType] = useState('monthly'); // State to track report type
  const isAdmin = localStorage.getItem("isAdmin");
  useEffect(() => {
    if (salesReps && salesReps.length > 0) {
      setSelectedRep(salesReps[0]); // Safeguarding against empty salesReps
    }
    axios.get('https://apijonluca.netlify.app/.netlify/functions/api/historycrm')
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  }, [salesReps]);

  return (
    <div className="bg-gray-100 p-8 min-h-screen">
      <Navbar loggedInUser={loggedInUser} />
      <div className="mx-32 bg-white shadow-lg p-6 rounded-lg">
        <div className='flex gap-x-16 items-start'>
        <h1 className="text-2xl font-bold text-gray-800">Reports Dashboard</h1>
        <div>
        <label className="block text-sm font-medium text-gray-700">Report Type:</label>
        <select
          className="mt-1 block p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          value={reportType}
          onChange={e => setReportType(e.target.value)}
        >
          <option value="monthly">Monthly</option>
          <option value="ytd">YTD</option>
        </select>
        </div>
        </div>
        {reportType === 'monthly' ? (
          <MonthlyReportContent data={data} salesReps={salesReps} setSelectedRep={setSelectedRep} selectedRep={selectedRep} isAdmin={isAdmin}/>
        ) : (
          <YTDReportContent data={data} salesReps={salesReps} setSelectedRep={setSelectedRep} selectedRep={selectedRep} isAdmin={isAdmin}/> // Assuming this component exists
        )}
      </div>
    </div>
  );
}
